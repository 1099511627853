import { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import State from "../../../../context";
import { Col, Flex, Row, theme } from "antd";
import { RadialBar } from "@ant-design/plots";
import CardSkeleton from "../../../../atom/CardSkeleton";
import IconContainer from "../../../../atom/IconContainer";

import StylesProvider from "./styles";

import pieChartConfig from "../../configs/pieChartConfig";
import radialBarConfig from "../../configs/radialBarConfig";

import {
  getCurrencyValueWithRoundSign,
  percentIncrease,
} from "../../../../utils/helper/general";
import {
  getLatestSavedProposalProps,
  getManagedUsers,
  getManagedUsersTotalProposedAmount,
  isOrganizationRivershares,
} from "../../../../utils/helper/specialized";

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { ReactComponent as Edit } from "../../images/edit.svg";

const LevelAnalyticsSection = ({ quarterAnalytics, radialBarSeries }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const proposedAumPercentIncrease = percentIncrease({
    compareValue:
      getManagedUsersTotalProposedAmount(
        quarterAnalytics.currentQuarterProposals
      ) ?? 0,
    compareWithValue:
      getManagedUsersTotalProposedAmount(
        quarterAnalytics.lastQuarterProposals
      ) ?? 0,

    maxPercent: 100,
    minPercent: -100,
  });

  const lastQuarterPercentIncrease = percentIncrease({
    compareValue: quarterAnalytics.currentQuarterProposals?.length ?? 0,
    compareWithValue: quarterAnalytics.lastQuarterProposals?.length ?? 0,
    maxPercent: 100,
    minPercent: -100,
  });

  const getTopProductsSeries = () => {
    let series = [];

    if (state.managedUsers?.length) {
      const selectedProductNames = [
        ...new Set(
          state.managedUsers
            .map(
              it =>
                it.selectedProduct ??
                getLatestSavedProposalProps(it.valueMap?.productMap)
                  ?.productName
            )
            .filter(it => it !== undefined)
        ),
      ];

      const totalSelectedProducts = state.managedUsers
        .filter(it => !it.isDeleted)
        .reduce(
          (acc, it) =>
            (it.selectedProduct ??
            getLatestSavedProposalProps(it.valueMap?.productMap)?.productName)
              ? acc + 1
              : acc,
          0
        );

      if (isOrganizationRivershares(state.organization?.name)) {
        series = selectedProductNames
          .map(name => ({
            name,
            y: state.managedUsers
              .filter(it => !it.isDeleted)
              .reduce(
                (acc, it) =>
                  (it.selectedProduct ??
                    getLatestSavedProposalProps(it.valueMap?.productMap)
                      ?.productName) === name
                    ? acc + 1
                    : acc,
                0
              ),
          }))
          .sort((a, b) => b.y - a.y);
      } else {
        const top3SelectedProducts = selectedProductNames
          .map(name => ({
            name,
            y: state.managedUsers
              .filter(it => !it.isDeleted)
              .reduce(
                (acc, it) =>
                  (it.selectedProduct ??
                    getLatestSavedProposalProps(it.valueMap?.productMap)
                      ?.productName) === name
                    ? acc + 1
                    : acc,
                0
              ),
          }))
          .sort((a, b) => b.y - a.y)
          .slice(0, 3)
          .map(it => ({
            ...it,
            color: it.name.includes("ETF")
              ? token.financialProductEtfColor
              : undefined,
          }));

        series = [
          ...top3SelectedProducts,
          {
            name: "Rest",
            y:
              totalSelectedProducts -
              top3SelectedProducts.reduce((acc, it) => acc + it.y, 0),
          },
        ];
      }
    }

    return series;
  };

  return (
    <StylesProvider token={token}>
      <Flex gap={24} style={{ width: "100%" }}>
        <div className={"statistic-card"}>
          <Flex align={"center"} justify={"space-between"}>
            <b className={"value"}>
              {getCurrencyValueWithRoundSign(
                getManagedUsersTotalProposedAmount(
                  quarterAnalytics.currentQuarterProposals
                )
              )}
            </b>
            <b
              style={{
                fontSize: 17,
                color:
                  proposedAumPercentIncrease < 0
                    ? token.colorError
                    : token.colorGreen,
              }}
            >
              {proposedAumPercentIncrease}%{" "}
              {proposedAumPercentIncrease < 0 ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowUpOutlined />
              )}
            </b>
          </Flex>
          <span className={"label"}>Proposed this quarter</span>
          <p className={"description"}>
            {getCurrencyValueWithRoundSign(
              getManagedUsersTotalProposedAmount(
                quarterAnalytics.lastQuarterProposals
              )
            )}{" "}
            proposed last quarter
          </p>
        </div>
        <div className={"statistic-card"}>
          <Flex align={"center"} justify={"space-between"}>
            <b className={"value"}>
              {quarterAnalytics.currentQuarterProposals?.length ?? 0}
            </b>
            <b
              style={{
                fontSize: 17,
                color:
                  lastQuarterPercentIncrease < 0
                    ? token.colorError
                    : token.colorGreen,
              }}
            >
              {lastQuarterPercentIncrease}%{" "}
              {lastQuarterPercentIncrease < 0 ? (
                <ArrowDownOutlined />
              ) : (
                <ArrowUpOutlined />
              )}
            </b>
          </Flex>
          <span className={"label"}>Proposals this quarter</span>
          <p className={"description"}>
            {quarterAnalytics.lastQuarterProposals?.length ?? 0} proposals last
            quarter
          </p>
        </div>
        <div className={"statistic-card"}>
          <b className={"value"}>{getManagedUsers(state)?.length}</b>
          <span className={"label"}>Total Clients</span>
        </div>
      </Flex>
      <Flex gap={23} style={{ width: "100%" }}>
        <CardSkeleton loading={state.loading} width={470} height={196}>
          <Row
            style={{
              width: "66%",
              background: "#ffffff",
              padding: "10px 12px",
              borderRadius: 8,
              boxShadow: "0px 0px 16px #0000000D",
            }}
          >
            <Col span={12}>
              <RadialBar {...radialBarConfig} data={radialBarSeries} />
            </Col>
            <Col span={12}>
              <Flex vertical gap={2}>
                <Row justify={"end"} style={{ color: token.colorPrimary }}>
                  <IconContainer
                    size={28}
                    background={"#E7EBEE"}
                    pointer={!state.loading}
                    onClick={() =>
                      !state.loading && state.openModal("quarterlyGoalsModal")
                    }
                  >
                    <Edit />
                  </IconContainer>
                </Row>
                <h3
                  style={{
                    fontSize: 24,
                    color: "#1E1919",
                    margin: "0 0 9px",
                  }}
                >
                  Quarterly Goals
                </h3>
                <Flex align={"center"} gap={4}>
                  <div
                    className={"marker"}
                    style={{ background: token.quarterlyGoalProposalColor }}
                  />
                  <span style={{ color: token.quarterlyGoalProposalColor }}>
                    {quarterAnalytics.currentQuarterProposals?.length}/
                    {state.quarterly?.proposals ?? 0} Proposals Generated
                  </span>
                </Flex>
                <Flex align={"center"} gap={4}>
                  <div
                    className={"marker"}
                    style={{ background: token.quarterlyGoalClientColor }}
                  />
                  <span style={{ color: token.quarterlyGoalClientColor }}>
                    {quarterAnalytics.currentQuarterConversions?.length}/
                    {state.quarterly?.clientConversion ?? 0} Client Conversions
                  </span>
                </Flex>
                <Flex align={"center"} gap={4}>
                  <div
                    className={"marker"}
                    style={{ background: token.quarterlyGoalAumColor }}
                  />
                  <span style={{ color: token.quarterlyGoalAumColor }}>
                    {getCurrencyValueWithRoundSign(
                      getManagedUsersTotalProposedAmount(
                        quarterAnalytics.currentQuarterProposals
                      )
                    )}
                    /{getCurrencyValueWithRoundSign(state.quarterly?.newAUM)}{" "}
                    New AUM
                  </span>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </CardSkeleton>
        <Flex
          align="center"
          style={{
            width: "33%",
            background: "#ffffff",
            paddingTop: 18,
            borderRadius: 8,
            boxShadow: "0px 0px 16px #0000000D",
          }}
          vertical
        >
          {isOrganizationRivershares(state.organization?.name) ? (
            <b>Product Usage</b>
          ) : (
            <>
              <b>Top 3 Products</b>
              <span>vs. the Rest</span>
            </>
          )}
          {getTopProductsSeries().length && (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...pieChartConfig,
                colors: token.topProductsPieChartPallet,
                series: {
                  data: getTopProductsSeries(),
                },
              }}
            />
          )}
        </Flex>
      </Flex>
    </StylesProvider>
  );
};

export default LevelAnalyticsSection;
