import PageAdminAdvisors from "../../../page/PageAdminAdvisors";
import PageAdminClients from "../../../page/PageAdminClients";
import PageMarketing from "../../../page/PageMarketing";
import PageProducts from "../../../page/PageProducts";

import BoxOpen from "../../../icon/BoxOpen";
import DocumentPage from "../../../icon/DocumentPage";
import User from "../../../icon/User";
import Users from "../../../icon/Users";

export default [
  {
    path: "/bluepoint-admin-portal/advisors",
    Component: PageAdminAdvisors,
    Icon: User,
    text: "Advisors",
  },
  {
    path: "/bluepoint-admin-portal/products",
    Component: PageProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/bluepoint-admin-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
  {
    path: "/bluepoint-admin-portal/clients",
    Component: PageAdminClients,
    Icon: Users,
    text: "Clients",
  },
];
