import styled from "styled-components";
import { Button, Flex, Row } from "antd";

export const ChartTypeButtonsContainer = styled(Row)`
  position: absolute;
  background: ${props => props.background};
  top: 9px;
  left: 9px;
  padding: 4px;
  border-radius: 25px;
  z-index: 1;

  @media screen and (max-width: 550px) {
    position: initial;
    padding: 6px;
    margin-bottom: -24px;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 143px;
  height: 30px;
  font-weight: 500;
  background-color: transparent;
  color: #fff;
  padding: 0 12px;
  border-radius: 25px;
  border-color: transparent;

  &.active {
    background-color: ${props => props.activebackground};
    color: ${props => props.activecolor};
    pointer-events: none;
  }

  &.ant-btn:disabled {
    background: transparent;
  }

  @media screen and (max-width: 550px) {
    height: 36px;
  }
`;

export const Marker = styled.div`
  height: 14px;
  width: 14px;
  min-width: 14px;
  border-radius: 2px;
`;

export const NoContributionsContainer = styled(Flex)`
  font-size: 14px;
  background: #e9f3f8;
  color: #1c5568;
  padding: 26px;
  border-radius: 12px;
  margin: 12px 0;
`;
