import styled from "styled-components";
import { Flex } from "antd";

export const StyledCard = styled(Flex)`
  flex-grow: 1;
  flex-wrap: nowrap;
  max-width: ${props => props.max_width};
  width: ${props => props.width};
  min-width: ${props => props.min_width};
  min-height: ${props => props.min_height ?? props.height};
  background: ${({ token }) => token.productCardBackground};
  border-left: 12px solid ${props => props.accent_color};
  border-top: ${({ token }) => token.productCardBorder};
  border-right: ${({ token }) => token.productCardBorder};
  border-bottom: ${({ token }) => token.productCardBorder};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  overflow: auto;
  margin: 8px;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .title {
    width: 100%;
    line-height: 26px;
    font-size: 22px;
    font-weight: 500;
    color: #2a2828;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .top-label {
    font-size: 13px;
  }

  .objective {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-btn-default {
    height: 36px;
  }

  .ant-btn.ant-btn-sm {
    height: 28px;
    min-height: 28px;
  }
`;
