import styled from "styled-components";
import { Flex, InputNumber, Modal, Typography } from "antd";
import InputContainer from "../../../../atom/InputContainer";

import Close from "../../../../icon/Close";
import { isPortalAdmin } from "../../../../utils/helper/specialized";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 34px;
  }

  & .ant-modal-footer {
    margin-top: 33px;
  }
`;

const DefaultProductAssumptionsModal = ({
  handleClose,
  investmentAssumptions,
  open,
}) => (
  <StyledModal
    centered
    closeIcon={<Close />}
    footer={false}
    onCancel={handleClose}
    open={open}
    title={"Default Product Assumptions"}
    width={415}
  >
    <Typography.Paragraph
      style={{
        background: "#4897D126",
        borderRadius: 12,
        padding: 20,
      }}
    >
      {isPortalAdmin()
        ? "All products shown use the following defaults. The ability to change the defaults will be made available in the near future."
        : "All products shown use the following defaults."}
    </Typography.Paragraph>
    <Flex gap={16} vertical>
      <InputContainer labelColor={"#8396A6"} labelText={"Initial Investment"}>
        <InputNumber
          className={"input-custom"}
          readOnly={true}
          value={investmentAssumptions.investmentAmount}
        />
      </InputContainer>
      <InputContainer labelColor={"#8396A6"} labelText={"Length of Investment"}>
        <InputNumber
          className={"input-custom"}
          readOnly={true}
          value={investmentAssumptions.investmentDuration}
        />
      </InputContainer>
      <InputContainer labelColor={"#8396A6"} labelText={"Annual Target Income"}>
        <InputNumber
          className={"input-custom"}
          formatter={value => (value?.includes("%") ? value : `${value}%`)}
          max={100}
          min={1}
          parser={value => value.replace("%", "")}
          readOnly={true}
          value={investmentAssumptions.withdrawalLevel}
        />
      </InputContainer>
      <InputContainer
        labelColor={"#8396A6"}
        labelText={"Begin Withdrawal after"}
      >
        <InputNumber
          className={"input-custom"}
          placeholder={"N/A"}
          readOnly={true}
          value={investmentAssumptions.yearToStartWithdrawals}
        />
      </InputContainer>
    </Flex>
  </StyledModal>
);

export default DefaultProductAssumptionsModal;
