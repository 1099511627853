import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { ConfigProvider, Flex, Tabs, theme } from "antd";
import RecentLeadCard from "./components/RecentLeadCard";
import RecentProposalCard from "./components/RecentProposalCard";

import StylesContainer from "./styles";

import { isOrganizationOneascent } from "../../../../utils/helper/specialized";

const RecentUsersSection = ({
  recentProposalsList,
  hideLeads,
  hideClients,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const isOneascent = isOrganizationOneascent(
    state.orgName ?? state.organization?.name
  );

  const getDefaultActiveTab = () => {
    if (hideClients) {
      return "leads";
    } else {
      return "clients";
    }
  };

  const items = [
    {
      key: "clients",
      label: isOneascent ? "Recent Proposals" : "Recent Clients",
      children: (
        <Flex gap={15} vertical>
          {recentProposalsList?.map((it, index) => (
            <RecentProposalCard
              key={index}
              organization={state.organization?.name ?? state.orgName}
              userData={it}
            />
          ))}
        </Flex>
      ),
      hidden: hideClients,
    },
    {
      key: "leads",
      label: "Recent Leads",
      children: (
        <Flex gap={15} vertical>
          {state.managedUsers
            ?.filter(it => it.userType === "Assigned")
            ?.sort(
              (a, b) =>
                dayjs(b.updateDateTime?.$date) - dayjs(a.updateDateTime?.$date)
            )
            ?.slice(0, 4)
            ?.map((it, index) => (
              <RecentLeadCard key={index} leadData={it} />
            ))}
        </Flex>
      ),
      hidden: hideLeads,
    },
  ].filter(it => !it.hidden);

  return (
    <StylesContainer gap={18} vertical>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: token.advisorDashboardPageActiveTabColor,
              lineWidthBold: 5,
            },
          },
        }}
      >
        <Tabs defaultActiveKey={getDefaultActiveTab()} items={items}></Tabs>
      </ConfigProvider>
    </StylesContainer>
  );
};

export default RecentUsersSection;
