import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, ConfigProvider, Flex, Form, Tabs, theme } from "antd";
import TabGoalInfo from "./components/TabGoalInfo";
import TabUser from "./components/TabUser";

import StyledModal from "./styles";

import { tableItemTypes } from "./constants";
import {
  DEFAULT_RETIREMENT_AGE,
  NEW_CLIENT_DEFAULT_DATA,
} from "../../../../utils/constant";

import {
  addManagedUser,
  addManagedUserExpanded,
  openGoalsManagedUser,
  openManagedUser,
  setLastAdvisorLogin,
  updateManagedUser,
} from "../../../../utils/request/manager";
import {
  isAdminView,
  isManagerAccessAffiliateOnly,
} from "../../../../utils/helper/specialized";
import {
  getCurrencyValue,
  isValidEmail,
  stringToCamelCase,
} from "../../../../utils/helper/general";

import Close from "../../../../icon/Close";
import { ReactComponent as IconOpenClientView } from "../../images/icon_open_client_view.svg";

const ModalNextManagedUserData = ({ handleClose, open }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [editView, setEditView] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isGoalsPlanningType, setIsGoalsPlanningType] = useState(false);
  const [showBasicSection, setShowBasicSection] = useState();

  const managedUserData = state.managedUsers?.find(
    it => it.email === state.activeEmail
  );

  useEffect(() => {
    if (managedUserData) {
      setEditView(true);

      form.setFieldsValue({
        ...managedUserData,
        assets: managedUserData.valueMap?.guideInitialData?.assets,
        enableOnboardingQuestions:
          managedUserData.enableOnboardingQuestions ??
          managedUserData.valueMap?.guideInitialData
            ?.enableOnboardingQuestions ??
          true,
        maritalStatus: managedUserData.valueMap?.guideInitialData?.isMarried
          ? "married"
          : "single",
        oldEmail: managedUserData.email,
        phoneAlt: managedUserData.valueMap?.guideInitialData?.phoneAlt,
        phoneNumber: managedUserData.valueMap?.guideInitialData?.phoneNumber,

        planningType: stringToCamelCase(
          managedUserData.planningType ??
            managedUserData.valueMap?.leadInitial?.planningType ??
            "Cashflow"
        ),
        retirementAge:
          managedUserData.retirementAge ??
          managedUserData.valueMap?.guideInitialData?.retirementAge ??
          DEFAULT_RETIREMENT_AGE,
        yearBorn:
          managedUserData.birthYear ??
          managedUserData.yearBorn ??
          managedUserData.valueMap?.guideInitialData?.yearBorn,
      });

      setIsGoalsPlanningType(
        (
          managedUserData.planningType ??
          managedUserData.valueMap?.leadInitial?.planningType
        )?.toLowerCase() === "goals" ||
          isManagerAccessAffiliateOnly(state.access)
      );
      setFormValues(form.getFieldsValue());
    } else {
      setEditView(false);
      form.resetFields();
      form.setFieldsValue(NEW_CLIENT_DEFAULT_DATA);

      if (state.access?.affiliate) {
        form.setFieldValue("planningType", "Goals");
        setIsGoalsPlanningType(true);
      }

      setFormValues(form.getFieldsValue());
    }

    setShowBasicSection(
      formValues.planType === "basic" || !formValues.planType
    );
  }, [open]);

  const handleViewTypeChange = e =>
    setShowBasicSection(e.target.value === "basic");

  const setFormValue = (name, value) => {
    form.setFieldValue(name, value);
    setFormValues(form.getFieldsValue());
  };

  const onFinish = () => {
    const formValues = form.getFieldsValue();

    if (!isGoalsPlanningType) {
      if (!isValidEmail(formValues.email)) {
        state.showWarning("Invalid email address, please re-enter.");
        return;
      } else if (!formValues.firstName) {
        state.showWarning("Enter new user First name, please.");
        return;
      } else if (!formValues.lastName) {
        state.showWarning("Enter new user Last name, please.");
        return;
      } else if (
        formValues.yearBorn &&
        formValues.startDate &&
        formValues.yearBorn > dayjs(formValues.startDate.$d).format("YYYY")
      ) {
        state.showWarning("Birth Year could not be bigger, than Start Date.");
        return;
      } else if (
        formValues.planType === "basic" &&
        formValues.savingsPerMonth > formValues.annualIncome / 12
      ) {
        state.showWarning(
          `You need to increase Annual Income value to save ${getCurrencyValue(formValues.savingsPerMonth)} monthly`
        );
        return;
      }
    }

    state.setKeyValue("loading", true);

    if (formValues.oldEmail) {
      updateManagedUser(formValues)
        .then(() => {
          state.setAdminData().then(() => {
            handleClose();
            state.showSuccess("Client data updated");
          });
        })
        .catch(console.log);
    } else {
      if (!formValues.planType || formValues.planType === "basic") {
        const requestBody = {
          doNotNotifyManagedUser: true,
          maritalStatus: "single",
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          startDate: formValues.startDate?.$d ?? new Date(),
          planningType: "Goals",
          ...formValues,
        };

        addManagedUser(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            handleClose();

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      } else {
        const requestBody = {
          doNotNotifyManagedUser: true,
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          ...formValues,
          startDate: formValues.startDate?.$d ?? new Date(),
          accounts: JSON.stringify(
            formValues.accounts.map(accountData => ({
              account_name: accountData.accountType,
              account_type: tableItemTypes.find(
                it => it.title === accountData.accountType
              ).type,
              starting_balance: accountData.startingBalance,
              growth: accountData.growth,
              start_age: accountData.startAge,
              end_age: accountData.endAge,
            }))
          ),
          incomes: JSON.stringify(
            formValues.incomes.map(incomeData => ({
              income_name: incomeData.incomeType,
              income_type: tableItemTypes.find(
                it => it.title === incomeData.incomeType
              ).type,
              annual_amount: incomeData.annualAmount,
              start_age: incomeData.startAge,
              end_age: incomeData.endAge,
            }))
          ),
          expenses: JSON.stringify(
            formValues.expenses.map(expenseData => ({
              expense_name: expenseData.expenseType,
              expense_type: tableItemTypes.find(
                it => it.title === expenseData.expenseType
              ).type,
              amount: expenseData.amount,
              frequency: expenseData.frequency,
              start_age: expenseData.startAge,
              end_age: expenseData.endAge,
            }))
          ),
        };

        addManagedUserExpanded(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            handleClose();

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      }
    }
  };

  const onFieldsChange = changedFields => {
    if (changedFields[0].name[0] === "planningType") {
      setIsGoalsPlanningType(changedFields[0].value.toLowerCase() === "goals");
    }
  };

  const handleOpenClientView = () => {
    if (isGoalsPlanningType) {
      openGoalsManagedUser(state.activeEmail);
    } else {
      openManagedUser(state.activeEmail);
    }

    setLastAdvisorLogin(state.activeEmail).then(() => state.setAdminData());
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: token.colorRadioButton,
          },
        },
      }}
    >
      <StyledModal
        className={`add-client-modal ${editView ? "edit-view" : ""}`}
        closeIcon={<Close />}
        footer={
          state.activeEmail ? (
            ""
          ) : (
            <Flex gap={20}>
              <Button
                disabled={state.loading || isAdminView()}
                form={"clientInfoForm"}
                onClick={onFinish}
                htmlType={"button"}
                shape={"round"}
                type={"primary"}
              >
                Create User
              </Button>
              <Button
                htmlType={"button"}
                onClick={handleClose}
                shape={"round"}
                style={{ background: "#E6F4EF", color: token.colorPrimary }}
                type={"text"}
              >
                Close
              </Button>
            </Flex>
          )
        }
        onCancel={handleClose}
        open={open}
        title={
          <Flex
            align={"center"}
            justify={"space-between"}
            style={{ paddingRight: 40 }}
          >
            <b style={{ fontSize: 18, color: "#1B5568" }}>Client Information</b>
            {editView && (
              <Button
                onClick={handleOpenClientView}
                shape={"round"}
                size={"small"}
                style={{ width: 170, background: "#E6F4EF", color: "#1B5568" }}
                type={"primary"}
              >
                <Flex align={"center"} gap={4}>
                  <span>Open Advisor View</span>
                  <IconOpenClientView />
                </Flex>
              </Button>
            )}
          </Flex>
        }
        width={568}
      >
        <Form
          form={form}
          id={"clientInfoForm"}
          layout={"vertical"}
          onFieldsChange={onFieldsChange}
          onFinish={onFinish}
        >
          {isGoalsPlanningType ? (
            <TabUser
              editView={editView}
              isGoalsPlanningType={isGoalsPlanningType}
              managerAccess={state.access}
            />
          ) : (
            <Tabs
              items={[
                {
                  key: "clientInfo",
                  label: "Client Info",
                  children: (
                    <TabUser
                      editView={editView}
                      isGoalsPlanningType={isGoalsPlanningType}
                      managerAccess={state.access}
                    />
                  ),
                },
                {
                  key: "goalInfo",
                  label: "Base Information",
                  children: (
                    <TabGoalInfo
                      handleViewTypeChange={handleViewTypeChange}
                      handleUserDataChange={setFormValue}
                      newUserData={formValues}
                      showBasicSection={showBasicSection}
                    />
                  ),
                },
              ]}
            />
          )}
          {editView && (
            <p className={"asterisk"}>
              <i>
                * To make any edits to your client’s information, please access
                their plan to make any changes.
              </i>
            </p>
          )}
        </Form>
      </StyledModal>
    </ConfigProvider>
  );
};

export default ModalNextManagedUserData;
