import { useMediaQuery } from "react-responsive";
import { Button, Row } from "antd";
import CardProduct from "../../../../molecule/CardProduct";

import StyledModal from "./styles";

import { BASIC_GROWTH_RATES_ID_LIST } from "../../constants";

import Close from "../../../../icon/CloseOutlined";

const ModalOtherGrowthTypes = ({
  cardActions,
  compareProducts,
  handleCardClick,
  handleClose,
  open,
  productsList,
  selectedProduct,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 390 });
  const cardWidth = isMobile ? "100%" : "267px";

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={
        <Row justify={"start"}>
          <Button
            type={"primary"}
            shape={"round"}
            style={{ width: 107 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Row>
      }
      open={open}
      onCancel={handleClose}
      title={"Growth Types"}
      width={736}
    >
      <p className={"list-title"}>
        <span style={{ fontWeight: 500 }}>Growth Rates</span> (Basic)
      </p>
      <Row className={"list"}>
        {productsList
          ?.filter(product => BASIC_GROWTH_RATES_ID_LIST.includes(product._id))
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              key={product._id}
              product={product}
              actions={cardActions}
              height={"190px"}
              width={cardWidth}
              minWidth={cardWidth}
              maxWidth={cardWidth}
              handleCardClick={handleCardClick}
              labels={[product.investmentPhilosophy]}
              isActionButtonActive={selectedProduct === product._id}
            />
          ))}
      </Row>
      <p className={"list-title"}>
        <span style={{ fontWeight: 500 }}>Asset Classes</span> (Intermediate)
      </p>
      <Row className={"list"}>
        {productsList
          ?.filter(product => !BASIC_GROWTH_RATES_ID_LIST.includes(product._id))
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              key={product._id}
              product={product}
              actions={cardActions}
              width={cardWidth}
              minWidth={cardWidth}
              maxWidth={cardWidth}
              handleCardClick={handleCardClick}
              labels={[product.investmentPhilosophy]}
              isActionButtonActive={selectedProduct === product._id}
            />
          ))}
      </Row>
    </StyledModal>
  );
};

export default ModalOtherGrowthTypes;
