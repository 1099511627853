import sidebar_logo from "../images/sidebar_logo.svg";

export default {
  colorPrimary: "#215054",
  comparisonProductsColors: ["#4B4747", "#2C9FE0", "#FFC700"],
  incomeChartWithTypesNavbarButtonBackground: "#fff",
  incomeChartWithTypesNavbarButtonColor: "#1E1919",
  incomeChartWithTypesNavbarContainerBackground: "#749B9E",
  sidebarBackground: "#749B9E",
  sidebarIconColorActive: "#1E1919",
  sidebarLogo: sidebar_logo,
  sidebarAvatarBackground: "#e3ebeb",
  sidebarAvatarColor: "#1E1919",
  sidebarIconLayoutVertical: true,
  sidebarMenuItemHoverBackground: "transparent",
  sidebarWidth: 87,
  tenantColorPrimary: "#88D2DC",
};
