import { Flex } from "antd";
import Header from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { ReactComponent as Demo } from "./image/demo.svg";

const ReportPagePersonalityIntro = ({ personaName }) => (
  <StylesContainer id={"reportPagePersonalityIntro"}>
    <Flex className={"page-container"} gap={29} vertical>
      <Header personaName={personaName} />
      <Flex gap={113}>
        <Flex vertical>
          <h2>Thank you for taking the Perspectives Assessment</h2>
          <h5>
            This simple quiz captures exactly what its title suggests—the points
            of view, dispositions, and tendencies we bring to the table when
            interacting with wealth.
          </h5>
          <p>
            Every day, everyone interacts with the Four Uses of Money: Spending,
            Saving, Giving, and Investing. No one Use is better or worse than
            another. All are important, and it’s equally important to know which
            are most natural for us. If we can identify our thoughts and
            feelings about the Four Uses of Money, we can play to our strengths
            and account for our blind spots.
          </p>
        </Flex>
        <div>
          <Demo />
        </div>
      </Flex>

      <Flex className={"table"} gap={128}>
        <Flex gap={20} vertical>
          <div>
            <p>
              <b>Spending:</b> Money is flowing out for near-term benefit.
              Spending empowers us to enjoy our wealth.
            </p>
            <p>Examples include…</p>
            <ul>
              <li>Booking a vacation</li>
              <li>Upgrading the car</li>
              <li>Clicking “add to cart”</li>
            </ul>
          </div>

          <div>
            <p>
              <b>Saving:</b> Money is staying in for near-term needs. Saving
              empowers us to preserve our wealth.
            </p>
            <ul>
              <li>Building your emergency fund</li>
              <li>Preparing for purchases</li>
              <li>Increasing peace of mind</li>
            </ul>
          </div>
        </Flex>

        <Flex gap={20} vertical>
          <div>
            <p>
              <b>Giving:</b> Money is flowing out for future benefit. Giving
              empowers us to share our wealth.
            </p>
            <ul>
              <li>Donations to charity</li>
              <li>Tithing to your church</li>
              <li>Impacting your community</li>
            </ul>
          </div>

          <div>
            <p>
              <b>Investing:</b> Money is staying in for future needs. Investing
              empowers us to grow our wealth.
            </p>
            <ul>
              <li>Contributing to retirement accounts</li>
              <li>Opening a college fund</li>
              <li>Diversifying your assets</li>
            </ul>
          </div>
        </Flex>
      </Flex>

      <p>
        After scoring your answers in our proprietary algorithm, we produced
        your top Perspectives Profile matches, and you have selected the option
        that you believe is most true of you today.
        <br />
        This assessment is the beginning of the conversation, not the end! Our
        Perspectives change as times and circumstances change. Vocalize the
        parts of your results that do and don’t align with your relationship
        with money. Conversations bring clarity, and clarity brings confidence!
      </p>
    </Flex>
  </StylesContainer>
);

export default ReportPagePersonalityIntro;
