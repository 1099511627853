import { useContext } from "react";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import MenuActions from "../MenuActions";
import ModalPrioritiesQuickAdd from "../../../ModalPrioritiesQuickAdd";

import StylesContainer from "./styles";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";

const CardPriorities = () => {
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");

  const handleActionQuickAdd = prioritiesFor => {
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("openModalPrioritiesQuickAdd", true);
  };

  const handleActionReTakeQuiz = prioritiesFor => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("reTakePrioritiesQuizMode", true);
    state.setKeyValue("openModalPriorities", true);
  };

  const handleCloseModalPrioritiesQuickAdd = () => {
    state.setKeyValue("prioritiesFor", null);
    state.setKeyValue("openModalPrioritiesQuickAdd", false);
  };

  const handleLaunchPriorities = prioritiesFor => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("reTakePrioritiesQuizMode", false);
    state.setKeyValue("surveyLinkFor", prioritiesFor);
    state.setKeyValue("openModalSurveyLink", true);
  };

  const handlePrioritiesQuickAdd = newValues => {
    state
      .setPreferenceValue(state.prioritiesFor, {
        ...state.getPreferenceValue(state.prioritiesFor),
        picked: newValues
          .filter(it => it.priority)
          .sort((a, b) => a.priority - b.priority)
          .map(it => it.label),
        root: state
          .getPreferenceValue(state.prioritiesFor)
          .root.filter(it => it !== null),
      })
      .then(() => {
        state.showSuccess("Quick-add Priorities saved");
        state.setKeyValue("openModalPrioritiesQuickAdd", false);
        state.setKeyValue("prioritiesFor", null);
      })
      .catch(error => console.log(error));
  };

  const handleViewPrioritiesIndividual = prioritiesFor => {
    state.setKeyValue("reTakePrioritiesQuizMode", false);
    state.setKeyValue("viewResultsPrioritiesQuizMode", true);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("openModalPriorities", true);
  };

  const handleViewPrioritiesCouple = () => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("viewPrioritiesCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const ButtonPrioritiesMy = () =>
    state.getPreferenceValue("prioritiesMy") ? (
      <Flex gap={16}>
        <Button
          block
          onClick={() => handleViewPrioritiesIndividual("prioritiesMy")}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View {state.personalInfo?.firstName}'s
        </Button>
        <MenuActions
          items={[
            {
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("prioritiesMy"),
            },
            {
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPriorities("prioritiesMy"),
            },
            {
              onClick: () => handleActionQuickAdd("prioritiesMy"),
              key: "add",
              label: "Quick-add answers",
            },
          ]}
        />
      </Flex>
    ) : (
      <Button
        disabled={isAdminView()}
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape={"round"}
        type={"primary"}
      >
        {state.personalInfo?.firstName}: Launch
      </Button>
    );

  const ButtonPrioritiesSpouse = () =>
    state.getPreferenceValue("prioritiesSpouse") ? (
      <Flex gap={16}>
        <Button
          block
          onClick={() => handleViewPrioritiesIndividual("prioritiesSpouse")}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View {guideInitialData?.spouseFirstName}'s
        </Button>
        <MenuActions
          items={[
            {
              onClick: () => handleActionReTakeQuiz("prioritiesSpouse"),
              key: "take",
              label: "Re-take quiz",
            },
            {
              onClick: () => handleLaunchPriorities("prioritiesSpouse"),
              key: "send",
              label: "Re-send quiz",
            },
            {
              onClick: () => handleActionQuickAdd("prioritiesSpouse"),
              key: "add",
              label: "Quick-add answers",
            },
          ]}
        />
      </Flex>
    ) : (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape={"round"}
        type={"primary"}
      >
        {guideInitialData?.spouseFirstName}: Launch
      </Button>
    );

  const ButtonPrioritiesCouple = () =>
    state.getPreferenceValue("prioritiesFamily") ? (
      <Button
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"primary"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button
        disabled={
          !state.getPreferenceValue("prioritiesMy") ||
          !state.getPreferenceValue("prioritiesSpouse")
        }
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        type={"primary"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  const getSelectedValues = () => {
    if (state.getPreferenceValue("guideInitialData")?.isMarried) {
      return state.getPreferenceValue("prioritiesFamily")?.commonPicks ?? null;
    } else {
      return state.getPreferenceValue("prioritiesMy")?.picked ?? null;
    }
  };

  return (
    <StylesContainer className={"card-next-up"} vertical>
      <Flex gap={40} justify={"space-between"}>
        <Flex gap={16} vertical>
          <h5 className={"title"}>Priorities</h5>
          <p className={"description"}>
            Our why helps us stay on track.
            <br />
            What matters most to you?
            <br />
            <br />({"<"}2 minutes per person)
          </p>
        </Flex>
        <Flex gap={16} vertical>
          <ButtonPrioritiesMy />
          {guideInitialData?.isMarried && (
            <>
              <ButtonPrioritiesSpouse />
              <ButtonPrioritiesCouple />
            </>
          )}
        </Flex>
      </Flex>
      {getSelectedValues() && (
        <Flex gap={10} vertical>
          {getSelectedValues().map((label, index) => (
            <p className={"row-selected-value"} key={index}>
              <b className={"index"}>{++index}</b>
              <span>{label?.replace("[my]", "")?.replace("[spouse]", "")}</span>
            </p>
          ))}
        </Flex>
      )}

      <ModalPrioritiesQuickAdd
        onCancel={handleCloseModalPrioritiesQuickAdd}
        open={state.openModalPrioritiesQuickAdd}
        onSave={handlePrioritiesQuickAdd}
        selectedValues={state.getPreferenceValue(state.prioritiesFor)?.picked}
      />
    </StylesContainer>
  );
};

export default CardPriorities;
