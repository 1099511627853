import { ReactComponent as HeroIconStep1 } from "../images/hero_icon_step_1.svg";
import { ReactComponent as HeroIconStep2 } from "../images/hero_icon_step_2.svg";
import { ReactComponent as HeroIconStep3 } from "../images/hero_icon_step_3.svg";

export const defaultProposalData = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 500000,
  investmentDuration: 20,
  investmentObjective: "growth",
  organization: "level2",
  riskTolerance: "Moderate",
  yearToStartWithdrawals: 1,
};

export const listStepData = [
  {
    description: `<p>Having clear financial goals helps us make smarter decisions today and sets us up for freedom and peace of mind down the road.</p><p>What are your thoughts on where we could take our financial journey?</p>`,
    HeroIcon: () => <HeroIconStep1 />,
    heroDescription: `<p>Goals are like starting points; they provide focus, clarity and direction. By creating manageable steps, goals can reduce complexity and the anxiety that comes with decision-making.</p><p>Don’t worry, you can always explore and adjust this later.</p>`,
    heroTitle: "Having a goal helps keep your money on track.",
    title: "What’s your aim?",
  },
  {
    description:
      "To effectively achieve your financial goals, we need to choose an investment strategy that not only aligns with your comfort level, but also balances potential risks. It’s important to select an approach that offers the growth needed, while managing the level of risk you're willing to accept.",
    HeroIcon: () => <HeroIconStep2 />,
    heroDescription:
      "Each investment carries different risks and rewards. If you don’t have much experience yet, we have lots of ways to help you learn more. Don’t worry, you can always explore and adjust this later.",
    heroTitle: "Every goal is powered by actual investments.",
    title: "What level of risk are you willing to take?",
  },
  {
    description:
      "Let’s set some basic parameters for this journey. Please select amount you want to invest, and for how long.",
    HeroIcon: () => <HeroIconStep3 />,
    heroDescription:
      "With just a few key pieces of information, we can start visualizing your trajectory. Seeing where your money is taking you helps you make informed decisions and stay on course to reach your goals.",
    heroTitle: "Wherever you’re starting from, we can help.",
    title: "Amount & Time",
  },
];
