import {
  Button,
  ConfigProvider,
  Flex,
  Form,
  InputNumber,
  Radio,
  Select,
  theme,
} from "antd";

import StyledForm from "./styles";

import { DEFAULT_ADVISOR_FEE } from "../../../../../../../../utils/constant";
import { OPTIONS_GOAL_TYPE } from "../../../../../../../proposal/constant";
import { OPTIONS_GOAL_TYPE_RIVERSHARES } from "../../../../../../../rivershares/constants";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../../../../../utils/helper/general";
import {
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationAstor,
  isOrganizationGoals,
  isOrganizationRivershares,
} from "../../../../../../../../utils/helper/specialized";

import { ReactComponent as TargetAmount } from "../../../../../../../../icon/target_marker.svg";

const InvestmentAssumptions = ({
  handleProposalDataChange,
  managerAccess,
  openGoalSettingsModal,
  organization,
  proposalData,
}) => {
  const { token } = theme.useToken();

  const getRadioOptions = () => {
    if (isOrganizationRivershares(organization)) {
      return OPTIONS_GOAL_TYPE_RIVERSHARES.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ));
    } else {
      return OPTIONS_GOAL_TYPE.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ));
    }
  };

  const handleInitialInvestmentChange = e => {
    const cleanValue = cleanCurrencyValue(e.target?.value);
    const validatedValue =
      cleanValue > 10000000 ? 10000000 : cleanValue < 0 ? 0 : cleanValue;

    if (!isNaN(validatedValue)) {
      handleProposalDataChange("investmentAmount", +validatedValue);
      e.target.value = getCurrencyValue(validatedValue);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: "#ebf1f3",
            colorBorder: "#ebf1f3",
            colorText: "#354a5f",
            optionSelectedBg: "#ebf1f3",
          },
        },
      }}
    >
      <StyledForm layout={"vertical"}>
        <Flex gap={32} vertical>
          <Form.Item hidden={isOrganizationGoals(organization)}>
            <Radio.Group
              value={proposalData?.investmentObjective}
              onChange={e =>
                handleProposalDataChange("investmentObjective", e.target.value)
              }
            >
              <Flex gap={16} vertical>
                {getRadioOptions()}
              </Flex>
            </Radio.Group>
          </Form.Item>

          <Flex gap={16} vertical>
            <Form.Item
              hidden={!isOrganizationAstor(organization)}
              label={"Portfolio Template"}
            >
              <Select
                onChange={value =>
                  handleProposalDataChange("riskTolerance", value)
                }
                options={[
                  {
                    label: "20/80 Income",
                    value: "income",
                  },
                  {
                    label: "40/60 Conservative",
                    value: "conservative",
                  },
                  {
                    label: "60/40 Moderate",
                    value: "moderate",
                  },
                  {
                    label: "80/20 Aggressive",
                    value: "aggressive",
                  },
                  {
                    label: "100 Equity",
                    value: "equity",
                  },
                ]}
                value={proposalData?.riskTolerance}
              ></Select>
            </Form.Item>
            <Form.Item
              hidden={!isOrganizationAstor(organization)}
              label={"Product Allocation"}
            >
              <Select
                onChange={value =>
                  handleProposalDataChange("allocation", value)
                }
                options={[
                  {
                    label: "100%",
                    value: 100,
                  },
                  {
                    label: "90%",
                    value: 90,
                  },
                  {
                    label: "80%",
                    value: 80,
                  },
                  {
                    label: "70%",
                    value: 70,
                  },
                  {
                    label: "60%",
                    value: 60,
                  },
                  {
                    label: "50%",
                    value: 50,
                  },
                  {
                    label: "40%",
                    value: 40,
                  },
                  {
                    label: "30%",
                    value: 30,
                  },
                  {
                    label: "20%",
                    value: 20,
                  },
                  {
                    label: "10%",
                    value: 10,
                  },
                ]}
                value={proposalData?.allocation}
              ></Select>
            </Form.Item>
            <Form.Item label={"Initial Investment"}>
              <InputNumber
                step={1000}
                min={0}
                max={10000000}
                value={proposalData?.investmentAmount}
                style={{ width: "100%" }}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onChange={value =>
                  handleProposalDataChange("investmentAmount", value)
                }
                onClick={selectValue}
                onKeyUp={handleInitialInvestmentChange}
              />
            </Form.Item>
            <Form.Item label={"Length of Investment"}>
              <InputNumber
                value={proposalData?.investmentDuration}
                min={0}
                max={80}
                style={{ width: "100%" }}
                formatter={value => `${value} years`}
                parser={value => value?.replace(" years", "")}
                onChange={value =>
                  handleProposalDataChange("investmentDuration", value)
                }
                onClick={selectValue}
              />
            </Form.Item>
            <Form.Item
              hidden={isGrowthTypeRiskTolerance(
                proposalData?.investmentObjective
              )}
              label={"Investment Withdrawal in Retirement"}
            >
              <InputNumber
                min={0}
                max={100}
                value={cleanPercentValue(proposalData?.withdrawalLevel)}
                formatter={value => (value ? `${value}%` : "")}
                parser={value => value.replace("%", "")}
                style={{ width: "100%" }}
                onChange={value =>
                  handleProposalDataChange("withdrawalLevel", value)
                }
                onClick={selectValue}
              />
            </Form.Item>
            <Form.Item
              hidden={isGrowthTypeRiskTolerance(
                proposalData?.investmentObjective
              )}
              label={"Begin Withdrawal after"}
            >
              <InputNumber
                min={1}
                max={80}
                value={proposalData?.yearToStartWithdrawals}
                formatter={value => (value ? `${value} years` : "")}
                parser={value => value?.replace(" years", "")}
                style={{ width: "100%" }}
                onChange={value =>
                  handleProposalDataChange("yearToStartWithdrawals", value)
                }
                onClick={selectValue}
              />
            </Form.Item>
          </Flex>

          <Flex align={"start"} gap={8}>
            <Flex vertical>
              <span>
                {isGrowthTypeRiskTolerance(proposalData?.investmentObjective)
                  ? "Target Amount"
                  : "End Target Amount"}
              </span>
              <b>{getCurrencyValue(proposalData?.targetAmount)}</b>
            </Flex>
            <TargetAmount style={{ marginTop: 4 }} />
          </Flex>

          <Flex gap={22} vertical>
            {isGrowthTypeRiskTolerance(proposalData?.investmentObjective) && (
              <Flex vertical>
                <span>Monthly Contributions</span>
                <b>
                  {getCurrencyValue(
                    Math.round(proposalData?.contributions / 12)
                  )}
                </b>
              </Flex>
            )}
            <Flex vertical>
              <span>Risk Tolerance</span>
              <b style={{ textTransform: "capitalize" }}>
                {proposalData?.riskTolerance}
              </b>
            </Flex>
            <Flex vertical>
              <span>Advisor Fee</span>
              <b>
                {getPercentValue(
                  proposalData?.advisorFee ?? DEFAULT_ADVISOR_FEE
                )}
              </b>
            </Flex>
          </Flex>

          <Button
            block
            disabled={isLockedByAdminView({ managerAccess })}
            onClick={openGoalSettingsModal}
            shape={"round"}
            size={"small"}
          >
            Edit Settings
          </Button>
        </Flex>
      </StyledForm>
    </ConfigProvider>
  );
};

export default InvestmentAssumptions;
