import { useEffect, useState } from "react";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import {
  Button,
  Col,
  InputNumber,
  Row,
  Space,
  Switch,
  theme,
  Typography,
} from "antd";
import ChartSkeleton from "../../../../atom/ChartSkeleton";
import InputContainer from "../../../../atom/InputContainer";

import { getMcChartConfig } from "../../../../utils/chart";
import { selectValue } from "../../../../utils/helper/general";
import {
  DEFAULT_BIRTH_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
  MC_DEFAULT_SETTINGS,
} from "../../../../utils/constant";
import {
  getLiveSimulation,
  getSimulations,
} from "../../../../utils/request/regularApp";

HighchartsMore(Highcharts);

const { Text, Paragraph } = Typography;

const McSection = ({
  hideMcSettingsToggler,
  investmentAssumptions,
  liveAssessment,
  organization = "level2",
  product = {},
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [liveSimulation, setLiveSimulation] = useState();
  const [useMcSettings, setUseMcSettings] = useState(true);
  const [mcSettings, setMcSettings] = useState();

  useEffect(() => {
    const mcDefaultSettings = getMcDefaultSettings();

    if (mcDefaultSettings) {
      setMcSettings(mcDefaultSettings);
      updateSimulations(mcDefaultSettings);
    }
  }, [product]);

  const updateSimulations = mcDefaultSettings => {
    setLoading(true);

    if (product?.productName === "initial") {
      getSimulations("initial", false).then(data => {
        setLoading(false);
        setLiveSimulation(data);
      });
    } else {
      const requestBody = {
        organization: organization?.toLowerCase(),
        productId: product._id,
        objective: {
          ...investmentAssumptions,
          ...product,
          birthYear: product.currentAge
            ? undefined
            : (product.birthYear ?? DEFAULT_BIRTH_YEAR),
          investmentDuration:
            product.investmentDuration ??
            +investmentAssumptions.investmentDuration,
          withdrawalLevel: product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL,
        },
        inputSettings: useMcSettings
          ? {
              props: mcDefaultSettings ?? mcSettings,
            }
          : {},
      };

      getLiveSimulation({
        montecarlo: true,
        historicalAnalysis: true,
        ...requestBody,
      })
        .then(data => {
          setLoading(false);
          setLiveSimulation(data);
        })
        .catch(console.log);
    }
  };

  const getArearangeRangeData = list =>
    list.map((it, index) => [
      it[0],
      liveSimulation.props.trajectoryPercentile0thMC[index][1] < 0
        ? 0
        : liveSimulation.props.trajectoryPercentile0thMC[index][1],
      it[1],
    ]);

  const getMcSeries = (series = []) => {
    liveAssessment?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        type: "spline",
        lineWidth: 2,
        color: "#537CF1",
        zoneAxis: "x",
        zIndex: 40,
        data: liveAssessment.grandTotalColumn,
      });

    liveSimulation?.props?.trajectoryPercentile90thMC?.length &&
      series.push({
        name: "Monte Carlo 90th Percentile",
        type: "arearange",
        color: "transparent",
        lineWidth: 0,
        zIndex: 30,
        fillColor: "#C6E3EA45",
        data: getArearangeRangeData(
          liveSimulation.props.trajectoryPercentile90thMC
        ),
      });

    liveSimulation?.props?.trajectoryMedianMC?.length &&
      series.push({
        name: "Monte Carlo Median",
        type: "arearange",
        color: "transparent",
        lineWidth: 0,
        zIndex: 30,
        fillColor: "#2050AA25",
        data: getArearangeRangeData(liveSimulation.props.trajectoryMedianMC),
      });

    liveSimulation?.props?.trajectoryPercentile10thMC?.length &&
      series.push({
        name: "Monte Carlo 10th Percentile",
        type: "arearange",
        color: "transparent",
        lineWidth: 0,
        zIndex: 20,
        fillColor: "#A01C8733",
        data: getArearangeRangeData(
          liveSimulation.props.trajectoryPercentile10thMC
        ),
      });

    return series;
  };

  const handleMcSettingsChange = (key, value) =>
    setMcSettings(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const handleSave = () => updateSimulations();

  const getMcDefaultSettings = () => ({
    ...MC_DEFAULT_SETTINGS,
    mcExpectedReturn:
      product?.properties?.mcExpectedReturn ??
      MC_DEFAULT_SETTINGS.mcExpectedReturn,
    mcVolatilityReturn:
      product?.properties?.mcVolatilityReturn ??
      MC_DEFAULT_SETTINGS.mcVolatilityReturn,
  });

  const handleReset = () => {
    setMcSettings(getMcDefaultSettings());
    updateSimulations(getMcDefaultSettings());
  };

  return (
    <Row>
      <Col span={24}>
        <ChartSkeleton loading={loading} height={250}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...getMcChartConfig(
                product?.currentAge
                  ? dayjs().format("YYYY") - product.currentAge
                  : product?.birthYear
              ),
              series: getMcSeries(),
            }}
          />
        </ChartSkeleton>
      </Col>
      <Row
        gutter={[24, 24]}
        style={{
          marginTop: 24,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={12}>
          <Paragraph
            style={{
              fontSize: 18,
              color: token.colorBlack,
            }}
          >
            About Monte Carlo
          </Paragraph>
          <Paragraph>
            This tool uses a Monte Carlo analysis to simulate the impact of
            market returns/volatility. A Monte Carlo simulation is calculated by
            running the tool projections 1,000 separate times.
          </Paragraph>
          <Paragraph>
            Some sequences of returns used in the Monte Carlo simulation will
            give you better results, and some will give you worse results.
          </Paragraph>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 34 }}
          >
            <Text
              style={{
                color: token.colorBlack,
                fontSize: 16,
              }}
            >
              Monte Carlo Settings
            </Text>
            {!hideMcSettingsToggler && (
              <Switch
                defaultChecked={useMcSettings}
                onChange={() => setUseMcSettings(!useMcSettings)}
              />
            )}
          </Row>
          <Space direction="vertical" size={16}>
            <Space size={19}>
              <InputContainer labelText="Expected Return %">
                <InputNumber
                  min={0}
                  max={100}
                  value={mcSettings?.mcExpectedReturn}
                  formatter={value => `${value}%`}
                  parser={value => value.replace("%", "")}
                  className="input-custom"
                  onClick={selectValue}
                  onChange={value =>
                    handleMcSettingsChange("mcExpectedReturn", value)
                  }
                />
              </InputContainer>
              <InputContainer labelText="Return Volatility %">
                <InputNumber
                  min={0}
                  max={100}
                  value={mcSettings?.mcVolatilityReturn}
                  formatter={value => `${value}%`}
                  parser={value => value.replace("%", "")}
                  className="input-custom"
                  onClick={selectValue}
                  onChange={value =>
                    handleMcSettingsChange("mcVolatilityReturn", value)
                  }
                />
              </InputContainer>
            </Space>
            <Space size={19}>
              <InputContainer labelText="Expected Inflation %">
                <InputNumber
                  min={0}
                  max={100}
                  value={mcSettings?.mcExpectedInflation}
                  formatter={value => `${value}%`}
                  parser={value => value.replace("%", "")}
                  className="input-custom"
                  onClick={selectValue}
                  onChange={value =>
                    handleMcSettingsChange("mcExpectedInflation", value)
                  }
                />
              </InputContainer>
              <InputContainer labelText="Inflation Volatility %">
                <InputNumber
                  min={0}
                  max={100}
                  value={mcSettings?.mcVolatilityInflation}
                  formatter={value => `${value}%`}
                  parser={value => value.replace("%", "")}
                  className="input-custom"
                  onClick={selectValue}
                  onChange={value =>
                    handleMcSettingsChange("mcVolatilityInflation", value)
                  }
                />
              </InputContainer>
            </Space>
            <Space>
              <Button
                type="primary"
                size="small"
                shape="round"
                style={{ width: 77 }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                type="text"
                size="small"
                shape="round"
                style={{ width: 77 }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Space>
          </Space>
        </Col>
      </Row>
    </Row>
  );
};

export default McSection;
