export const OPTIONS_GOAL_TYPE = [
  {
    value: "growth",
    label: "Drive Growth",
  },
  {
    value: "income",
    label: "Drive Income",
  },
];
