import dayjs from "dayjs";
import { DatePicker, Input, InputNumber, Radio } from "antd";
import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";
import InputContainer from "../../../../../../atom/InputContainer";

import { DataContainer, StyledRadioGroup } from "./styles";

import { MIN_BIRTH_YEAR } from "../../../../../../utils/constant";

const CreateGoalStepFillData = ({ newGoalData, state, updateNewGoalData }) => {
  const goalType = newGoalData.goalType;

  return (
    <DataContainer vertical gap={22}>
      {goalType === "other" && (
        <InputContainer labelText={"Name your goal"}>
          <Input
            id={"goalNameInput"}
            className={"input-custom"}
            value={newGoalData.goalName}
            placeholder={"Placeholder..."}
            onChange={event =>
              updateNewGoalData("goalName", event.target.value)
            }
            onClick={selectValue}
          />
        </InputContainer>
      )}
      {goalType !== "emergencySavings" && (
        <>
          <InputContainer labelText={"Total Savings"}>
            <InputNumber
              id={"investmentAmountInput"}
              className={"input-custom"}
              min={0}
              max={100000000}
              step={1000}
              value={newGoalData.investmentAmount}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("investmentAmount", value)}
              onClick={selectValue}
            />
          </InputContainer>
          <InputContainer labelText={"Monthly Contributions"}>
            <InputNumber
              id={"contributionsInput"}
              className={"input-custom"}
              min={0}
              max={100000}
              step={100}
              value={newGoalData.contributions / 12}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("contributions", value * 12)}
              onClick={selectValue}
            />
          </InputContainer>
        </>
      )}
      {goalType === "savings" && (
        <InputContainer labelText={"Target Amount"}>
          <InputNumber
            id={"targetAmountInput"}
            className={"input-custom"}
            value={newGoalData.targetAmount}
            min={0}
            max={100000000}
            step={1000}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => updateNewGoalData("targetAmount", value)}
            onClick={selectValue}
          />
        </InputContainer>
      )}
      {(goalType === "savings" || goalType === "other") && (
        <InputContainer labelText={"Length of Investment"}>
          <InputNumber
            id={"investmentDurationInput"}
            className={"input-custom"}
            value={newGoalData.investmentDuration}
            min={1}
            max={80}
            onChange={value => updateNewGoalData("investmentDuration", value)}
            onClick={selectValue}
          />
        </InputContainer>
      )}
      {goalType === "income" && (
        <InputContainer labelText={"Birth Year"}>
          <DatePicker
            id={"birthYearInput"}
            className={"input-custom"}
            value={newGoalData.birthYear}
            picker={"year"}
            format={"YYYY"}
            placeholder={"YYYY"}
            disabledDate={current =>
              dayjs(current).isAfter(dayjs().format("YYYY")) ||
              dayjs(current).format("YYYY") < MIN_BIRTH_YEAR
            }
            onChange={date => updateNewGoalData("birthYear", date)}
          />
        </InputContainer>
      )}
      {goalType === "income" && (
        <InputContainer labelText={"Annual Retirement Withdrawal %"}>
          <InputNumber
            id={"withdrawalLevelInput"}
            className={"input-custom"}
            defaultValue={1}
            min={1}
            max={100}
            value={newGoalData.withdrawalLevel}
            formatter={value => `${value}%`}
            parser={value => value.replace("%", "")}
            onChange={value => updateNewGoalData("withdrawalLevel", value)}
            onClick={selectValue}
            style={{ width: 127 }}
          />
        </InputContainer>
      )}
      {goalType === "emergencySavings" && (
        <>
          <InputContainer labelText={"Total Monthly Expenses"}>
            <InputNumber
              id={"monthlyExpensesInput"}
              className={"input-custom"}
              inputMode={"numeric"}
              pattern={"[0-9]*"}
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.monthlyExpenses}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("monthlyExpenses", value)}
              onClick={selectValue}
            />
          </InputContainer>
          <u
            className={"pointer"}
            onClick={() => state.openModal("monthlyExpensesModal")}
            style={{
              fontWeight: 500,
            }}
          >
            Help me calculate
          </u>
          <InputContainer
            labelText={"How long do you want the savings to last?"}
          >
            <StyledRadioGroup
              onChange={e =>
                updateNewGoalData("investmentDuration", e.target.value)
              }
              optionType={"button"}
              value={newGoalData.investmentDuration}
            >
              <Radio value={0.25}>3 month</Radio>
              <Radio value={0.5}>6 month</Radio>
              <Radio value={1}>12 month</Radio>
            </StyledRadioGroup>
          </InputContainer>
          <InputContainer labelText={"Saved to date"}>
            <InputNumber
              id={"savedToDateInput"}
              className={"input-custom"}
              inputMode={"numeric"}
              pattern={"[0-9]*"}
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.investmentAmount}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("investmentAmount", value)}
              onClick={selectValue}
            />
          </InputContainer>
          <InputContainer
            labelText={"Savings each month until goal is reached"}
          >
            <InputNumber
              className={"input-custom"}
              id={"contributionsInput"}
              inputMode={"numeric"}
              pattern={"[0-9]*"}
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.contributions / 12}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("contributions", value * 12)}
              onClick={selectValue}
            />
          </InputContainer>
        </>
      )}
    </DataContainer>
  );
};

export default CreateGoalStepFillData;
