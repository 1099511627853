export const RIVERSHARES_CAPITAL_MARKET_ASSUMPTIONS_LINK =
  "https://ontrajectory.s3.amazonaws.com/rivershares/Capital_Market_Assumptions.pdf";

export const OPTIONS_GOAL_TYPE_RIVERSHARES = [
  {
    value: "Accumulate",
    label: "Drive Accumulate",
  },
  {
    value: "Distribute",
    label: "Drive Distribute",
  },
];

export const RIVERSHARES_RISK_TYPES = [
  { value: "Income", label: "Income" },
  { value: "Income/Growth", label: "Income and Growth" },
  { value: "Growth", label: "Growth" },
];
