import { Divider, Flex, Image } from "antd";
import Header from "../../component/ReportHeader";
import SortableColumn from "../../../page/PageProposalDashboard/components/SortableColumn";

import StylesContainer from "./styles";

import demo from "./image/demo.png";

const ReportPagePriorities = ({ cards, personaName, userLastName }) => (
  <StylesContainer id={"reportPagePriorities"}>
    <div className={"page-container"}>
      <Header personaName={personaName} />

      <main>
        <header>
          <Flex gap={24} vertical>
            <h2>Priorities</h2>
            <p>
              Setting Priorities for your money helps align your financial
              decisions with your broader goals throughout your life. The space
              below is for you to reflect on how your Priorities might affect
              your short term and long term decisions.
            </p>
          </Flex>
          <Image preview={false} src={demo} />
        </header>

        <Flex gap={63}>
          <Flex className={"description-container"} gap={34} vertical>
            <h5>
              <b>{userLastName} Family Priorities</b>
            </h5>
            {cards?.length && (
              <SortableColumn
                containerStyle={{
                  display: "flex",
                  gap: 16,
                }}
                items={cards.filter(it => it !== null)}
              />
            )}
            <Flex gap={14} vertical>
              <h5>
                <b>Questions to ask?</b>
              </h5>
              <p>
                How will I implement these priorities into my financial decision
                making process?
              </p>
              <p>Where are we living up to these priorities?</p>
              <p>
                Where are we falling short or making decisions that put
                accomplishing these priorities in jeopardy?
              </p>
              <p>
                What is one change I can make today to start living out these
                priorities?
              </p>
            </Flex>

            <div>
              <Divider />
              <p>
                <i>
                  Reach out to your OneAscent Financial Advisor with any
                  questions or ideas to help implement these priorities in your
                  financial decision making.
                </i>
              </p>
            </div>
          </Flex>

          <Flex gap={10} vertical>
            <p>Personal Reflection</p>
            <div className={"personal-reflection-container"} />
          </Flex>
        </Flex>
      </main>
    </div>
  </StylesContainer>
);

export default ReportPagePriorities;
