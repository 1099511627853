import LogoLevel from "../images/logo.svg";
import NavBarMask from "../images/navbar_mask.png";

export default {
  adminPieChartColorPrimary: "#F36835",
  adminPieChartColorSecondary: "#BEC8CE",
  adminPieChartColorTertiary: "#354A5F",
  adminPieChartColorRest: "#72C0C7",
  colorAdminAnalyticValue: "#131523",
  colorPrimaryButton: "#428A16",
  colorPrimaryButtonText: "#FFFFFF",
  colorTertiaryButton: "#1B5568",
  logoSrc: LogoLevel,
  sidebarBackground: `url(${NavBarMask}) #2C4F71 right`,
  sidebarIconColor: "#BEC8CE",
  sidebarIconColorActive: "#FFFFFF",
};
