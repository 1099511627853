export default {
  advisorDashboardPageActiveTabColor: "#DBE247",
  adminPieChartColorPrimary: "red",
  adminPieChartColorSecondary: "red",
  adminPieChartColorTertiary: "red",
  adminPieChartColorRest: "red",
  colorPrimary: "#215054",
  colorRadioButton: "#4F8083",
  quarterlyGoalAumColor: "#DBE247",
  quarterlyGoalClientColor: "#4F8083",
  quarterlyGoalProposalColor: "#749B9E",
  pageDashboardRightSideSectionBackground: "#749b9e",
  pageDashboardRightSideSectionTitleColor: "#ffffff",
  pageDashboardRightSideSectionTitleCopy: "Market News & Updates",
  sidebarAvatarBackground: "#6DC1C7",
  sidebarAvatarColor: "#FFFFFF",
  sidebarBackground: "#03494E",
  sidebarIconColor: "#9AB6B8",
  sidebarIconColorActive: "#FEFEFE",
  sidebarMenuItemHoverBackground: "#052D2C",
  topProductsPieChartPallet: ["#296469", "#DBE247", "#03494E", "#749B9E"],
};
