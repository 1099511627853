import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, ConfigProvider, Flex, Input, Table, theme } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";

import { editLead } from "../../../../../../utils/request/manager";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  getLastSavedProposalProps,
  isAdminView,
  isManagerAccessLevelOnly,
  isOrganizationAstor,
  openProposalWithConfig,
} from "../../../../../../utils/helper/specialized";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as ImportIcon } from "../../../../images/import.svg";
import PlusOutlined from "../../../../../../icon/PlusOutlined";

const getColumns = ({
  getLeadType,
  handleArchiveLeadAction,
  handleCreateProposal,
  handleEditLeadAction,
  handleMoveLeadToClient,
  managerAccess,
  getProposalSavedDate,
  organization,
  searchedText,
}) =>
  [
    {
      dataIndex: "firstName",
      filteredValue: [searchedText],
      key: "leadName",
      onFilter: (value, record) =>
        String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (value, restValues) =>
        (isManagerAccessLevelOnly(managerAccess) ||
          isOrganizationAstor(organization)) &&
        !getProposalSavedDate(restValues) ? (
          <span>
            {restValues.firstName} {restValues.lastName}
          </span>
        ) : (
          <a
            className={"pointer"}
            onClick={() => handleEditLeadAction(restValues.email)}
          >
            <b>
              {restValues.firstName} {restValues.lastName}
            </b>
          </a>
        ),
      title: "Name",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      title: "Email",
    },
    {
      dataIndex: "leadType",
      hidden: isOrganizationAstor(organization),
      key: "leadType",
      render: (value, restValues) => getLeadType(restValues),
      title: "Lead Type",
    },
    {
      dataIndex: "planningType",
      key: "planningType",
      render: (planningType, allValues) =>
        isOrganizationAstor(organization) ? (
          "Goals"
        ) : (
          <span style={{ textTransform: "capitalize" }}>
            {planningType ?? allValues?.valueMap?.leadInitial?.planningType}
          </span>
        ),
      title: "Planning Type",
    },
    {
      dataIndex: "valueMap",
      key: "assets",
      render: (valueMap, restValues) =>
        getCurrencyValue(
          restValues.lastLoginTime
            ? restValues.currentAssets
            : (valueMap?.leadInitial?.assets ?? 0)
        ),
      title: "Assets",
    },
    {
      align: "right",
      hidden:
        !isManagerAccessLevelOnly(managerAccess) &&
        !isOrganizationAstor(organization),
      key: "proposalGenerated",
      render: (_, restValues) => {
        const proposalSavedDate = getProposalSavedDate(restValues);

        return proposalSavedDate ? (
          dayjs(proposalSavedDate).format("MM/DD/YYYY")
        ) : (
          <Button
            shape="round"
            size="small"
            onClick={() => handleCreateProposal(restValues.email)}
            disabled={isAdminView()}
          >
            Create Proposal
          </Button>
        );
      },
      sorter: (a, b) => dayjs(a.proposalGenerated) - dayjs(b.proposalGenerated),
      title: "Proposal Generated",
      width: 150,
    },
    {
      align: "right",
      dataIndex: "lastAdvisorLoginTime",
      key: "lastAdvisorLoginTime",
      render: it => it?.$date && dayjs(it.$date).format("MM/DD/YYYY"),
      title: "Advisor Last Login",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorUpdateTime",
      key: "lastAdvisorUpdateTime",
      render: it => it?.$date && dayjs(it.$date).format("MM/DD/YYYY"),
      title: "Advisor Last Update",
    },
    {
      className: "actions-menu-table-cell",
      dataIndex: "actionsMenu",
      key: "actionsMenu",
      render: (value, restValues) => (
        <MenuActions
          items={getItems({
            email: restValues.email,
            handleArchiveLeadAction,
            handleEditLeadAction,
            handleMoveLeadToClient,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getItems = ({
  email,
  handleArchiveLeadAction,
  handleEditLeadAction,
  handleMoveLeadToClient,
}) => [
  {
    key: "movetoClient",
    label: (
      <div onClick={() => handleMoveLeadToClient(email)}>Move to Client</div>
    ),
  },
  {
    key: "editLead",
    label: <div onClick={() => handleEditLeadAction(email)}>Edit Lead</div>,
  },
  {
    type: "divider",
  },
  {
    key: "archiveLead",
    label: (
      <div
        onClick={() => handleArchiveLeadAction(email)}
        style={{ color: "red" }}
      >
        Archive
      </div>
    ),
  },
];

const SectionMyLeads = ({ handleMoveLeadToClient, myLeadsList }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const organization = state.organization?.name ?? state.orgName;

  const getLeadType = leadData => {
    if (state.access?.level) {
      return "";
    } else if (state.access?.affiliate) {
      if (
        (leadData?.planningType?.toLowerCase() ??
          leadData?.valueMap?.leadInitial?.planningType?.toLowerCase()) ===
        "cashflow"
      ) {
        return "External";
      } else {
        return "Affiliate";
      }
    } else {
      return "External";
    }
  };

  const getProposalSavedDate = proposalData =>
    proposalData.proposalSavedDate?.$date ??
    (proposalData.valueMap?.productMap &&
      getLastSavedProposalProps(proposalData.valueMap.productMap)?.timestamp);

  const handleArchiveLeadAction = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveLeadModal");
  };

  const handleCreateProposal = email => {
    const proposalData = state?.managedUsers?.find(it => it.email === email);

    if (
      !proposalData?.valueMap?.guideInitialData ||
      proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
    ) {
      state.setKeyValue("activeEmail", email);
      state.setKeyValue("openModalProposalGuide", true);
    } else {
      openProposalWithConfig({
        organization,
        prospectType: state?.onboardingQuestionnaireType,
        userEmail: email,
      });
    }
  };

  const handleEditLeadAction = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("leadDetailsModal");
  };

  const handleImportLeadsClick = () => {
    state.openModal("importManagedUserModal");
  };

  const handleNewLeadModalOpen = () => {
    state.setKeyValue("activeEmail", null);
    state.openModal("leadDetailsModal");
  };

  return (
    <>
      <Flex
        align={"center"}
        justify={"space-between"}
        style={{ marginBottom: 16 }}
      >
        <Input
          style={{
            maxWidth: 228,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search my leads..."
          onChange={event => setSearchedText(event.target.value)}
          suffix={<SearchOutlined />}
        />
        <Flex gap={20}>
          <Button
            icon={<ImportIcon />}
            onClick={handleImportLeadsClick}
            shape={"round"}
            size={"small"}
            type={"text"}
          >
            Import Leads
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={handleNewLeadModalOpen}
            shape={"round"}
            size={"small"}
            style={{
              width: 125,
              background: token.buttonAddUserBackground,
              color: "#FFFFFF",
            }}
          >
            New Lead
          </Button>
        </Flex>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerColor: "#6C6C6C",
              colorText: "#516474",
              rowHoverBg: "#DCE14725",
            },
            Switch: {
              colorPrimary: "#4F8083",
              colorPrimaryHover: "#4F8083",
            },
          },
        }}
      >
        <Table
          columns={getColumns({
            getLeadType,
            getProposalSavedDate,
            handleArchiveLeadAction,
            handleCreateProposal,
            handleEditLeadAction,
            handleMoveLeadToClient,
            managerAccess: state.access,
            organization,
            searchedText,
          })}
          dataSource={myLeadsList}
          loading={state.loading}
          pagination={false}
          scroll={{
            x: 1250,
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default SectionMyLeads;
