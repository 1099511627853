import axios from "axios";

import {
  isAdminView,
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../helper/specialized";

export const addAdvisor = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/addUserManager`,
    payload,
    { withCredentials: true }
  );

export const addFolder = folderName =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/addFolder`,
    { folderName },
    { withCredentials: true }
  );

export const addLead = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/addLead`, payload, {
    withCredentials: true,
  });

export const addManagedUser = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/addManagedUser`,
    payload,
    { withCredentials: true }
  );

export const addManagedUserExpanded = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/addManagedUserExpanded`,
    payload,
    { withCredentials: true }
  );

export const addSubFolder = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/addSubFolder`, payload, {
    withCredentials: true,
  });

export const addProspect = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/addProspect`, payload, {
    withCredentials: true,
  });

export const addToFavoriteFinancialProducts = productId =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/favoriteFinancialProducts/${productId}`,
    {},
    { withCredentials: true }
  );

export const archiveLead = ({ userManagerEmail, email }) =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/archiveLead`,
    {
      userManagerEmail,
      email,
    },
    { withCredentials: true }
  );

export const archiveProposalGoal = payload =>
  axios.put(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/archiveProductMap`,
    payload,
    { withCredentials: true }
  );

export const changeOrgManagerPassword = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/changeOrgManagerPassword`,
    payload,
    { withCredentials: true }
  );

export const changeUserManagerPassword = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/changeUserManagerPassword`,
    payload,
    { withCredentials: true }
  );

export const convertLeadToClient = email =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/leadToClient`,
    { email },

    { withCredentials: true }
  );

export const deleteAdvisor = email =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/removeUserManager`,
    {
      email,
    },
    { withCredentials: true }
  );

export const deleteFile = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/removeFile`, payload, {
    withCredentials: true,
  });

export const deleteFolder = ({ folderId }) =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/removeFolder`,
    {
      folderId,
    },
    { withCredentials: true }
  );

export const deleteManagedUser = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/removeManagedUser`,
      { email },
      { withCredentials: true }
    )
    .then(response => response.data);

export const deleteProposal = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/deleteProposal`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const deleteProspect = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/deleteProspect`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const editLead = payload =>
  axios.put(`${process.env.REACT_APP_HOME_API_DOMAIN}/editLead`, payload, {
    withCredentials: true,
  });

export const getManagerConversations = managerEmail =>
  axios
    .get(
      `${process.env.REACT_APP_PENNY_MAIN_API}/conversation/userManager/${managerEmail}`
    )
    .then(({ data }) => data);

export const getOrganizationManagerData = () =>
  axios
    .get(`${process.env.REACT_APP_HOME_API_DOMAIN}/getOrgManagerData`, {
      withCredentials: true,
    })
    .then(response => response.data);

export const getUserManagerData = () =>
  axios
    .get(`${process.env.REACT_APP_HOME_API_DOMAIN}/getUserManagerData`, {
      withCredentials: true,
    })
    .then(response => response.data);

export const importCSVManagedUser = file => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/importCSVNextUser`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    }
  );
};

export const importCSVManagedUserLead = file => {
  const formData = new FormData();
  formData.append("file", file);

  return axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/importCSVNextUser`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    }
  );
};

export const importCSVManagedUserTemplate = ({ instanceLabel }) =>
  axios
    .get(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/${
        instanceLabel === "client"
          ? "importCSVNextUserTemplateClients"
          : "importCSVNextUserTemplateLeads"
      }`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    )
    .then(response => {
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, "import_list.csv");
        return;
      }
      // Other browsers
      // Create a link pointing to the ObjectURL containing the blob
      const blobURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", "import_list.csv");
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    });

export const markAsConvertedProspect = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/markAsConvertedProspect`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const markAsProspect = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/markAsProspect`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const notifyManagedUser = email =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/notifyManagedUser`,
      { email },
      { withCredentials: true }
    )
    .then(response => response.data);

export const openAdvisorView = (advisorEmail, organization) =>
  axios(
    `${
      process.env.REACT_APP_HOME_API_DOMAIN
    }/loadUserManager?email=${encodeURIComponent(advisorEmail)}`,
    { withCredentials: true }
  ).then(() => {
    if (organization === "OneAscent") {
      window.open("/oneascent-advisor-portal/dashboard", "adminView");
    } else {
      window.open("/level-advisor-portal/dashboard", "adminView");
    }
  });

export const openFile = id =>
  axios(`${process.env.REACT_APP_HOME_API_DOMAIN}/getFile?id=${id}`, {
    withCredentials: true,
  }).then(({ data }) => window.open(data, "_blank"));

let managedUserPopupWin;

export const openManagedUser = email => {
  const url = `${process.env.REACT_APP_HOME_API_DOMAIN}/loadManagedUser?email=${encodeURIComponent(email)}`;

  const args =
    "width=" +
    screen.availWidth * 0.45 +
    ",height=" +
    screen.height * 0.9 +
    "titlebar=yes,scrollbars=yes,resizable=yes,toolbar=no,directories=no,location=no,menubar=no,status=no,left=0,top=0";

  if (
    typeof managedUserPopupWin != "object" ||
    typeof managedUserPopupWin != "undefined"
  ) {
    managedUserPopupWin = window.open(url, "", args);
  } else {
    if (!managedUserPopupWin.closed) {
      managedUserPopupWin.close();
    }
    managedUserPopupWin = window.open(url, "", args);
  }

  managedUserPopupWin?.addEventListener(
    "load",
    () => {
      //if after loading page, we don't logged into user account - close popup
      managedUserPopupWin.close();
      console.log("No access to selected user account.");
    },
    false
  );

  if (managedUserPopupWin == null) {
    console.log(
      "Unable to launch windows 'side-by-side', please ensure pop-blocking is turned off."
    );
  }
};

let managedUserAsThemPopupWin;

export const openManagedUserAsThem = email => {
  const url = `${process.env.REACT_APP_HOME_API_DOMAIN}/loadManagedUser?email=${encodeURIComponent(email)}`;

  const args =
    "width=" +
    screen.availWidth * 0.45 +
    ",height=" +
    screen.height * 0.9 +
    "titlebar=yes,scrollbars=yes,resizable=yes,toolbar=no,directories=no,location=no,menubar=no,status=no,left=" +
    screen.width * 0.5 +
    ",top=0";

  if (typeof managedUserAsThemPopupWin !== "object") {
    managedUserAsThemPopupWin = window.open(
      url,
      "ManagedUserAsThemWindow",
      args
    );
  } else {
    if (!managedUserAsThemPopupWin.closed) {
      managedUserAsThemPopupWin.close();
    }
    managedUserAsThemPopupWin = window.open(
      url,
      "ManagedUserAsThemWindow",
      args
    );
  }

  setTimeout(() => {
    openManagedUser(email);
  }, 500);
};

export const openGoalsManagedUser = userEmail =>
  axios
    .get(
      `${
        process.env.REACT_APP_HOME_API_DOMAIN
      }/loadProposal?email=${encodeURIComponent(userEmail)}`,
      { withCredentials: true }
    )
    .then(() => {
      setTimeout(() => {
        window.open("/dashboard", "adminView");
        setLastAdvisorLogin(userEmail);
      }, 1000);
    });

export const openProposal = ({
  userEmail,
  userManagerEmail,
  organization,
  windowName,
}) =>
  axios
    .get(
      `${
        process.env.REACT_APP_HOME_API_DOMAIN
      }/loadProposal?email=${encodeURIComponent(
        userEmail
      )}&userManagerEmail=${userManagerEmail}&org=${organization}`,
      { withCredentials: true }
    )
    .then(() => {
      setTimeout(() => {
        if (isOrganizationOneascent(organization)) {
          window.open(
            "/oneascent-proposal/dashboard",
            windowName ?? (isAdminView() ? "adminView" : "")
          );
        } else if (isOrganizationRivershares(organization)) {
          window.open(
            "/rivershares-proposal",
            windowName ?? (isAdminView() ? "adminView" : "")
          );
        } else if (isOrganizationBluepoint(organization)) {
          window.open(
            "/bluepoint-proposal",
            windowName ?? (isAdminView() ? "adminView" : "")
          );
        } else if (isOrganizationAstor(organization)) {
          window.open(
            "/astor-proposal",
            windowName ?? (isAdminView() ? "adminView" : "")
          );
        } else {
          window.open(
            "/proposal/dashboard",
            windowName ?? (isAdminView() ? "adminView" : "")
          );
        }
      }, 1000);
    });

export const releaseLead = ({ email }) =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/leadToRelease`,
    {
      email,
    },
    { withCredentials: true }
  );

export const removeFromFavoriteFinancialProducts = productId =>
  axios.delete(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/favoriteFinancialProducts/${productId}`,
    { withCredentials: true }
  );

export const renameFile = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/renameFile`, payload, {
    withCredentials: true,
  });

export const renameFolder = payload =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/updateFolder`, payload, {
    withCredentials: true,
  });

export const restoreProposalGoal = payload =>
  axios.put(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/restoreProductMap`,
    payload,
    { withCredentials: true }
  );

export const setLastAdvisorLogin = email =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/setLastAdvisorLogin`,
    { email },
    { withCredentials: true }
  );

export const setLastLoginUser = email =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/setLastLoginUser`,
    { email },
    { withCredentials: true }
  );

export const setManagerMFAEnabled = isMfaEnabled =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/saveUserManagerMfaEnabled`,
    {
      isMfaEnabled,
    },
    { withCredentials: true }
  );

export const updateAdvisor = payload =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/updateUserManager`,
    payload,
    { withCredentials: true }
  );

export const updateFeatureContent = payload =>
  axios
    .put(`${process.env.REACT_APP_HOME_API_DOMAIN}/featureContent`, payload, {
      withCredentials: true,
    })
    .then(response => response.data);

export const updateLead = ({ email, payload }) =>
  axios.put(
    `${
      process.env.REACT_APP_API_GATEWAY
    }/user/valueMap/leadInitial/${encodeURIComponent(email)}`,
    payload,
    {
      headers: {
        "X-Api-Key": "98618f0a-7c01-405e-a696-e05bf5bb1f79",
      },
    }
  );

export const updateOrgVideoLink = link =>
  axios.put(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/orgVideo`,
    { video: link },
    {
      withCredentials: true,
    }
  );

export const updateQuarterlyGoals = payload =>
  axios.put(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/quarterlyGoals`,
    payload,
    { withCredentials: true }
  );

export const updateManagedUser = payload =>
  axios.put(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/updateManagedUser${
      payload.oldEmail ? `/${encodeURIComponent(payload.oldEmail)}` : ""
    }`,
    payload,
    { withCredentials: true }
  );

export const uploadFile = formData =>
  axios.post(`${process.env.REACT_APP_HOME_API_DOMAIN}/uploadFile`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    withCredentials: true,
  });
