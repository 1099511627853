import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import State from "../../context";
import { Flex, theme } from "antd";
import MenuActions from "../../atom/MenuActions";

import { logout } from "../../utils/request/regularApp";
import {
  isPortalOneascentAdvisor,
  openProposalWithConfig,
} from "../../utils/helper/specialized";

import { faUser } from "@fortawesome/free-solid-svg-icons";

const MenuProfile = ({ logoutLink = "/login" }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state] = useContext(State);

  const getItems = () =>
    [
      {
        key: "1",
        label: (
          <div onClick={() => state.openModal("accountSettingsModal")}>
            Settings
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            onClick={() =>
              openProposalWithConfig({
                organization: state.organization?.name ?? state.orgName,
              })
            }
          >
            Demo Mode
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        key: "3",
        label: (
          <div onClick={() => state.openModal("artBoardModal")}>
            Back to Launch
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        type: "divider",
      },
      {
        key: "4",
        label: (
          <div
            onClick={() =>
              logout()
                .then(() => navigate(logoutLink))
                .catch(console.log)
            }
          >
            Log Out
          </div>
        ),
      },
    ].filter(it => !it.hidden);

  return (
    state.personalInfo && (
      <MenuActions items={getItems()}>
        <Flex
          align={"center"}
          gap={12}
          style={{
            color: token.menuProfileColor,
            cursor: "pointer",
            padding: "8px 9px",
            textTransform: "capitalize",
          }}
        >
          <FontAwesomeIcon icon={faUser} style={{ fontSize: 15 }} />
          {state.personalInfo?.firstName} {state.personalInfo?.lastName}
        </Flex>
      </MenuActions>
    )
  );
};

export default MenuProfile;
