import dayjs from "dayjs";
import { Flex, Rate } from "antd";

import StylesContainer from "./styles";

const RecentLeadCard = ({ leadData }) => (
  <StylesContainer justify={"space-between"}>
    <Flex gap={3} vertical>
      <div className={"name"}>
        {leadData?.firstName} {leadData?.lastName}
      </div>
      <div>
        Received:{" "}
        {dayjs(leadData?.valueMap?.leadInitial?.createdAt).format("MM/DD/YYYY")}
      </div>
    </Flex>
    <Flex align={"end"} className={"phone"}>
      {leadData?.phoneNumber || " "}
    </Flex>
    <Flex align={"end"}>
      <u className={"email"}>{leadData?.email}</u>
    </Flex>
    <Rate disabled value={leadData?.valueMap?.leadInitial?.rate} />
  </StylesContainer>
);

export default RecentLeadCard;
