import { Dropdown } from "antd";

const MenuActions = ({ items, children, placement = "bottomRight" }) => (
  <Dropdown
    menu={{
      items,
    }}
    placement={placement}
    overlayClassNam="actions-menu"
    trigger={["click"]}
  >
    {children || <div className="actions-menu-toggler" />}
  </Dropdown>
);

export default MenuActions;
