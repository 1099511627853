import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  & .blurred-content {
    filter: blur(7px);
  }

  .aside-card {
    width: 100%;
    min-height: 102px;
    background: #ffffff;
    padding: 21px 20px;
    border-radius: 12px;
    cursor: pointer;
  }
`;

export default StylesContainer;
