import { Button, Flex, Image, theme } from "antd";

import StylesContainer from "./styles";

import { isLockedByAdminView } from "../../../../../../utils/helper/specialized";

import CloudsOutlined from "../../../../../../icon/CloudsOutlined";

const SectionIntroducingAdvice = ({
  handleGetAdviceButtonClick,
  isMobile,
  managerAccess,
}) => {
  const { token } = theme.useToken();

  return (
    <StylesContainer gap={14} vertical>
      <h3
        dangerouslySetInnerHTML={{
          __html: token.pageDashWantToTalkTitleContent,
        }}
      />
      <Flex align={"center"} gap={28}>
        <Flex gap={10} vertical>
          <p>
            You can click the “Get Advice” button any time to get in touch with
            a financial professional.
          </p>
          <p>
            <b
              dangerouslySetInnerHTML={{
                __html: token.pageDashWantToTalkLimitedContent,
              }}
            />
          </p>
        </Flex>
        <div>
          <Image
            preview={false}
            src={token.pageDashWantToTalkImageLink}
            width={130}
          />
        </div>
      </Flex>
      <Button
        disabled={isLockedByAdminView({ managerAccess })}
        icon={<CloudsOutlined />}
        onClick={handleGetAdviceButtonClick}
        shape={"round"}
        size={isMobile && "large"}
        style={{
          width: isMobile ? "100%" : 150,
          background: token.pageDashGetAdviceButtonBackground,
          color: token.pageDashGetAdviceButtonColor,
        }}
        type={"text"}
      >
        Get Advice
      </Button>
    </StylesContainer>
  );
};

export default SectionIntroducingAdvice;
