export const defaultAssetManagerFilters = [
  "iSectors&reg;",
  "State Street&reg;",
  "Goldman Sachs&reg;",
  "3D Asset Management&reg;",
  "Vanguard&reg;",
  "Capital Group&reg;",
];

export const defaultBroadCategoryFilters = [
  "Allocation",
  "Equity",
  "Fixed Income",
];
