import { useContext, useEffect } from "react";
import dayjs from "dayjs";
import State from "../../context";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  theme,
} from "antd";

import { addProspect, updateManagedUser } from "../../utils/request/manager";
import { selectValue } from "../../utils/helper/general";
import {
  getProspectUsers,
  isAdminView,
  isOrganizationOneascent,
} from "../../utils/helper/specialized";

import Close from "../../icon/Close";

const ModalAddProspect = ({ handleClose, open }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [form] = Form.useForm();

  const isMarriedFormValue = Form.useWatch("maritalStatus", form) === "married";
  const isOneascent = isOrganizationOneascent(state.organization?.name);
  const instanceLabel = isOneascent ? "Prospect" : "Lead";

  useEffect(() => {
    if (open) {
      form.resetFields();

      if (state.activeEmail) {
        const prospectData = getProspectUsers(state).find(
          it => it.email === state.activeEmail
        );

        form.setFieldsValue({
          firstName: prospectData?.firstName,
          lastName: prospectData?.lastName,
          email: prospectData?.email,
          maritalStatus: prospectData?.valueMap?.guideInitialData?.isMarried
            ? "married"
            : "single",
          birthYear:
            prospectData?.birthYear ??
            prospectData?.valueMap?.prospectObjective?.birthYear ??
            prospectData?.valueMap?.guideInitialData?.yearBorn,
          spouseFirstName:
            prospectData?.valueMap?.guideInitialData?.spouseFirstName,
          spouseLastName:
            prospectData?.valueMap?.guideInitialData?.spouseLastName,
          spouseEmail: prospectData?.valueMap?.guideInitialData?.spouseEmail,
          oldEmail: prospectData?.email,
        });
      } else {
        form.setFieldValue("maritalStatus", "single");
      }
    }
  }, [open]);

  const onFinish = values => {
    state.setKeyValue("loading", true);

    if (state.activeEmail) {
      updateManagedUser({
        ...values,
        oldEmail: state.activeEmail,
      })
        .then(response => {
          state.closeModal("addProspectModal");
          state.setKeyValue("loading", false);
          state.showSuccess(response.data);
          state.setAdminData();
        })
        .catch(({ response }) => {
          state.showError(response.data);
          state.setKeyValue("loading", false);
        });
    } else {
      addProspect(values)
        .then(response => {
          state.closeModal("addProspectModal");
          state.setKeyValue("loading", false);
          state.showSuccess(response.data);
          state.setAdminData();
        })
        .catch(({ response }) => {
          state.showError(response.data);
          state.setKeyValue("loading", false);
        });
    }
  };

  return (
    <Modal
      className={"add-prospect-modal"}
      title={
        state.activeEmail ? `Edit ${instanceLabel}` : `Add ${instanceLabel}`
      }
      open={open}
      onCancel={handleClose}
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={handleClose} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={state.loading || isAdminView()}
            form={"addProspectForm"}
            htmlType={"submit"}
            shape={"round"}
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            type={"primary"}
          >
            {state.activeEmail ? "Save Changes" : `Add ${instanceLabel}`}
          </Button>
        </Flex>
      }
    >
      <Form
        form={form}
        id={"addProspectForm"}
        layout={"vertical"}
        onFinish={onFinish}
        style={{ padding: 40 }}
      >
        <Flex align={"end"} gap={20}>
          <Form.Item
            label={"Name"}
            labelCol={{ span: 24 }}
            name={"firstName"}
            rules={[
              {
                required: true,
                message: "Please enter First Name",
              },
            ]}
          >
            <Input
              onClick={selectValue}
              placeholder={"First Name"}
              size={"large"}
              value={"test"}
            />
          </Form.Item>
          <Form.Item
            label={""}
            labelCol={{ span: 24 }}
            name={"lastName"}
            rules={[
              {
                required: true,
                message: "Please enter Last Name",
              },
            ]}
          >
            <Input
              placeholder={"Last Name"}
              size={"large"}
              onClick={selectValue}
            />
          </Form.Item>
        </Flex>
        <Flex align={"start"} gap={20}>
          <Form.Item
            label={"Email"}
            labelCol={{ span: 24 }}
            name={"email"}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              id={"userEmail"}
              onClick={selectValue}
              placeholder={"you@email.com"}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            hidden={isOneascent}
            label={"Birth Year"}
            labelCol={{ span: 24 }}
            name={"birthYear"}
            rules={[
              {
                type: "number",
                min: 1940,
                max: dayjs().format("YYYY") - 5,
              },
            ]}
          >
            <InputNumber
              id={"userBirthYear"}
              onClick={selectValue}
              placeholder={"YYYY"}
              size={"large"}
            />
          </Form.Item>
        </Flex>
        <Form.Item
          hidden={!isOneascent}
          label={"Marital Status"}
          name={"maritalStatus"}
          style={{ width: "100%" }}
        >
          <Radio.Group>
            <Flex vertical>
              <Radio value={"single"}>Single</Radio>
              <Radio value={"married"}>Married</Radio>
            </Flex>
          </Radio.Group>
        </Form.Item>
        <Flex align={"end"} gap={32}>
          <Form.Item
            hidden={!isOneascent || !isMarriedFormValue}
            label={"Spouse's Name"}
            name={"spouseFirstName"}
            rules={[
              {
                required: isMarriedFormValue,
                message: "Please enter Spouse's First Name",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input onClick={selectValue} size={"large"} />
          </Form.Item>
          <Form.Item
            hidden={!isOneascent || !isMarriedFormValue}
            name={"spouseLastName"}
            rules={[
              {
                required: isMarriedFormValue,
                message: "Please enter Spouse's Last Name",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input onClick={selectValue} size={"large"} />
          </Form.Item>
        </Flex>
        <Form.Item
          hidden={!isOneascent || !isMarriedFormValue}
          label={"Spouse's Email"}
          name={"spouseEmail"}
          rules={[
            {
              required: isMarriedFormValue,
            },
          ]}
        >
          <Input onClick={selectValue} size={"large"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddProspect;
