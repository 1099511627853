import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import State from "../../../../context";
import { Button, Flex, theme, Typography } from "antd";
import CardProduct from "../../../../molecule/CardProduct";
import ChartSkeleton from "../../../../atom/ChartSkeleton";
import SliderGoalFunded from "../SliderGoalFunded";

import StylesContainer from "./styles";

import { getRecommendedProductId } from "../../../proposal/component/ViewProposalGoal/helpers";
import { getLiveAssessment } from "../../../../utils/request/regularApp";
import {
  getGoalProgressLabel,
  isPortalBluepoint,
} from "../../../../utils/helper/specialized";
import {
  convertDecimalIntoDate,
  getCurrencyValue,
} from "../../../../utils/helper/general";
import { getActualAccountBalanceSeries } from "../../../../utils/chart";
import { getGoalTypeIcon } from "../../helpers";
import { getEmergencySavingsCalculations } from "../../pages/PageGoal/components/WorkspaceEmergencySavings/calculations";

const { Title, Paragraph } = Typography;

const CardGoalPreview = ({ goalData, config = {}, productsList }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state] = useContext(State);
  const [calculationsData, setCalculationsData] = useState();
  const isEmergencySavingsGoal =
    goalData?.goalType === "emergencySavings" ||
    goalData?.goalId === "emergencySavings";

  useEffect(() => {
    getLiveAssessment(goalData).then(data => setCalculationsData(data));
  }, []);

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleViewGoalClick = () => {
    const link = `${isPortalBluepoint() ? "/bluepoint" : ""}/goal/${(
      goalData.goalName ?? goalData.productName
    )
      ?.replaceAll(" ", "_")
      ?.replaceAll("%", "%25")}`;

    link && navigate(link);
  };

  const getProductCard = () => {
    const product = productsList?.find(
      it =>
        it._id ===
        (goalData?.productId ??
          getRecommendedProductId({
            organization: "goals",
            productsList: productsList,
            proposalData: goalData,
          }))
    );

    return (
      product && (
        <CardProduct
          handleCardClick={handleCardClick}
          labels={[product?.investmentPhilosophy]}
          product={product}
          width={"100%"}
        />
      )
    );
  };

  const getSeries = () => {
    const series = [];

    if (isEmergencySavingsGoal) {
      const { projectionSeries } = getEmergencySavingsCalculations({
        goalData,
        state,
      });

      projectionSeries && series.push(...projectionSeries);
    } else {
      calculationsData?.grandTotalColumn?.length &&
        series.push({
          lineWidth: 2,
          color: "#1B556889",
          zoneAxis: "x",
          dashStyle: "Dash",
          zones: [
            {
              value: 10,
              color: "#1B5568",
            },
          ],
          dataLabels: {
            enabled: false,
          },
          data: calculationsData.grandTotalColumn,
        });

      getActualAccountBalanceSeries(calculationsData, series);

      series.push({
        name: "Original investment amount",
        data: [
          [dayjs().format("YYYY") - goalData.birthYear, goalData.totalSavings],
        ],
        dataLabels: {
          enabled: true,
        },
      });

      calculationsData?.grandTotalColumn?.length &&
        calculationsData?.gFutureProgress?.length &&
        series.push({
          name: "Projected ending balance ",
          data: [
            [
              calculationsData.grandTotalColumn[
                calculationsData.grandTotalColumn.length - 1
              ][0],
              calculationsData.gFutureProgress[
                calculationsData.gFutureProgress.length - 1
              ][1],
            ],
          ],
          dataLabels: {
            enabled: true,
          },
        });
    }

    return series;
  };

  const emergencySavingsXAxis = {
    labels: {
      style: {
        fontSize: 14,
        fontFamily: "Inter, sans-serif",
        color: "#516474",
      },
      formatter: e => `Month ${e.value + 1}`,
    },
  };

  const defaultXAxis = {
    lineColor: "#B8B9BA",
    tickInterval: 1,
    tickLength: 0,
    showLastLabel: true,
    allowDecimals: false,
    minRange: 1,
    min: dayjs().format("YYYY") - goalData.birthYear,
    labels: {
      step: 1,
      style: {
        fontSize: 14,
        fontWeight: 500,
        fontFamily: token.fontFamily,
        color: "#25556F",
      },
      rotation: 0,
      formatter() {
        if (this.isFirst || this.isLast) {
          return this.value + goalData.birthYear;
        } else {
          return "";
        }
      },
    },
  };

  const chartConfig = {
    chart: {
      spacingRight: 20,
      spacingLeft: 20,
      type: "spline",
      height: 150,
      backgroundColor: "transparent",
    },
    legend: false,
    title: false,
    credits: false,
    tooltip: {
      formatter() {
        return isEmergencySavingsGoal
          ? `Month ${this.x + 1}: ${getCurrencyValue(this.y)}`
          : `${convertDecimalIntoDate(
              this.x + goalData.birthYear
            )}: ${getCurrencyValue(this.y)}`;
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },
    xAxis: isEmergencySavingsGoal ? emergencySavingsXAxis : defaultXAxis,
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        dataLabels: {
          style: {
            fontSize: 14,
            color: "#25556F",
          },
          formatter() {
            return getCurrencyValue(this.y);
          },
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    series: getSeries(),
  };

  return (
    <StylesContainer gap={19} vertical>
      <Flex gap={10}>
        {getGoalTypeIcon({
          goalName: goalData.goalName ?? goalData.productName,
          token,
        })}
        <Flex vertical style={{ width: "80%" }}>
          <Title className={"title"}>
            {goalData.goalName ?? goalData.productName}
          </Title>
          <Paragraph style={{ fontSize: 15, margin: 0 }}>
            {getGoalProgressLabel({ goalData, calculationsData })}
          </Paragraph>
        </Flex>
      </Flex>
      {!config.hideChart && (
        <ChartSkeleton loading={!calculationsData} height={150}>
          <div style={{ margin: "0 -14px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartConfig} />
          </div>
        </ChartSkeleton>
      )}
      <SliderGoalFunded
        goalData={goalData}
        customGoalFundedValue={
          isEmergencySavingsGoal
            ? getEmergencySavingsCalculations({
                goalData,
                state,
              }).goalFundedValue
            : undefined
        }
        calculationsData={calculationsData}
      />
      {!config.isMobile && getProductCard()}
      <Button
        block
        onClick={handleViewGoalClick}
        shape={"round"}
        size={config.isMobile && "large"}
        style={{
          fontSize: 16,
          background: token.buttonViewGoalBackground,
          border: "unset",
          marginTop: config.isMobile && 14,
        }}
      >
        View Goal
      </Button>
    </StylesContainer>
  );
};

export default CardGoalPreview;
