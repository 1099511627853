import { useContext } from "react";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import ChartPersonality from "../../../ChartPersonality";
import MenuActions from "../MenuActions";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";

const CardPerspectives = ({ handlePerspectivesViewResults }) => {
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");

  const handleLaunchPerspectives = perspectiveFor => {
    state.setKeyValue("perspectiveFor", perspectiveFor);
    state.setKeyValue("surveyLinkFor", perspectiveFor);
    state.setKeyValue("openModalSurveyLink", true);
  };

  const handleViewPerspectiveCouple = () => {
    state.setKeyValue("viewPerspectiveCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleActionReTakeQuiz = perspectiveFor => {
    state.setKeyValue("perspectiveFor", perspectiveFor);
    state.setKeyValue("openModalPerspectives", true);
  };

  const ButtonPerspectivesMy = () =>
    state.getPreferenceValue("perspectiveMy")?.selectedPersonality ? (
      <Flex gap={16}>
        <Button
          block
          onClick={() => handlePerspectivesViewResults("perspectiveMy")}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View {state.personalInfo?.firstName}'s
        </Button>
        <MenuActions
          items={[
            {
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("perspectiveMy"),
            },
            {
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPerspectives("perspectiveMy"),
            },
          ]}
        />
      </Flex>
    ) : state.getPreferenceValue("perspectiveMy") ? (
      <Button
        disabled={isAdminView()}
        onClick={() => handlePerspectivesViewResults("perspectiveMy")}
        shape={"round"}
        type={"primary"}
      >
        <Flex gap={10}>
          <span className={"perspective-step-marker"}>1/2</span>
          {state.personalInfo?.firstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        disabled={isAdminView()}
        onClick={() => handleLaunchPerspectives("perspectiveMy")}
        shape={"round"}
        type={"primary"}
      >
        <Flex gap={10}>{state.personalInfo?.firstName}: Launch</Flex>
      </Button>
    );

  const ButtonPerspectivesSpouse = () =>
    state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality ? (
      <Flex gap={16}>
        <Button
          block
          onClick={() => handlePerspectivesViewResults("perspectiveSpouse")}
          shape={"round"}
          style={{ background: "#209F00" }}
          type={"primary"}
        >
          View {guideInitialData?.spouseFirstName}'s
        </Button>
        <MenuActions
          items={[
            {
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("perspectiveSpouse"),
            },
            {
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPerspectives("perspectiveSpouse"),
            },
          ]}
        />
      </Flex>
    ) : state.getPreferenceValue("perspectiveSpouse") ? (
      <Button
        onClick={() => handlePerspectivesViewResults("perspectiveSpouse")}
        shape={"round"}
        type={"primary"}
      >
        <Flex gap={10}>
          <span className={"perspective-step-marker"}>1/2</span>
          {guideInitialData?.spouseFirstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPerspectives("perspectiveSpouse")}
        shape={"round"}
        type={"primary"}
      >
        <Flex gap={10}>{guideInitialData?.spouseFirstName}: Launch</Flex>
      </Button>
    );

  const ButtonPerspectivesCouple = () =>
    state.getPreferenceValue("perspectiveMy")?.selectedPersonality &&
    state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality ? (
      <Button
        onClick={handleViewPerspectiveCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"primary"}
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button disabled shape={"round"} type={"primary"}>
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  return (
    <Flex className={"card-next-up"} vertical>
      <Flex gap={40} justify={"space-between"}>
        <Flex gap={16} vertical>
          <h5 className={"title"}>Perspectives</h5>
          <p className={"description"}>
            We're all uniquely made. How do you think and feel about money
            today?
            <br />
            <br />({"<"}2 minutes per person)
          </p>
        </Flex>
        <Flex gap={16} vertical>
          <ButtonPerspectivesMy />
          {guideInitialData?.isMarried && (
            <>
              <ButtonPerspectivesSpouse />
              <ButtonPerspectivesCouple />
            </>
          )}
        </Flex>
      </Flex>
      {(state.getPreferenceValue("perspectiveMy")?.selectedPersonality ||
        state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality) && (
        <Flex align={"center"} className={"extra-copy-container"} gap={24}>
          <ChartPersonality
            examplePersonality={
              !guideInitialData?.isMarried &&
              state.getPreferenceValue("perspectiveMy")?.selectedPersonality
            }
            height={256}
            sizeSmall
            width={250}
          />
          <Flex vertical>
            {state.getPreferenceValue("perspectiveMy")?.selectedPersonality && (
              <>
                <span style={{ fontSize: 11 }}>
                  {state.personalInfo?.firstName}'s
                </span>
                <span style={{ fontSize: 11 }}>Perspectives Profile</span>
                <h3 style={{ margin: "5px 0 16px" }}>
                  {
                    state.getPreferenceValue("perspectiveMy")
                      .selectedPersonality
                  }
                </h3>
              </>
            )}
            {state.getPreferenceValue("perspectiveSpouse")
              ?.selectedPersonality && (
              <>
                <span style={{ fontSize: 11 }}>
                  {guideInitialData?.spouseFirstName}'s
                </span>
                <span style={{ fontSize: 11 }}>Perspectives Profile</span>
                <h3 style={{ margin: "5px 0 0" }}>
                  {
                    state.getPreferenceValue("perspectiveSpouse")
                      ?.selectedPersonality
                  }
                </h3>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default CardPerspectives;
