import { useContext, useEffect, useState } from "react";
import State from "../../context";
import { Button, Flex, theme } from "antd";
import ButtonDemoMode from "../../atom/ButtonDemoMode";

import { Container, StyledButton, StyledModal, StyledSelect } from "./styles";

import {
  handleCreateProposal,
  isPortalOneascentAdvisor,
} from "../../utils/helper/specialized";
import { openProposal } from "../../utils/request/manager";

import BottomChart from "./images/bottom_chart.svg";
import BottomLine from "./images/bottom_line.svg";
import { ReactComponent as OneAscentLogo } from "../../ecosystem/oneascent/image/logo_legacy.svg";
import { SearchOutlined } from "@ant-design/icons";

const ModalAdvisorArtBoard = () => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [managedUsers, setManagedUsers] = useState([]);
  const [isSelectOpened, setIsSelectOpened] = useState(false);

  useEffect(() => {
    if (!state.loading && !managedUsers?.length) {
      setManagedUsers(
        state.managedUsers
          ?.filter(it => !it.isDeleted && it.userType)
          ?.map(it => ({
            value: it.email,
            label: `${it.lastName}, ${it.firstName}`,
          }))
      );
    }
  }, [state]);

  const handleAddProspect = () => {
    state.openModal("addProspectModal");
  };

  const handleAddClient = () => {
    state.setKeyValue("openModalAddManagedUser", true);
  };

  const handleManagedUserSelect = email => {
    const proposalData = state.managedUsers?.find(it => it.email === email);

    if (proposalData?.proposalSavedDate || proposalData?.valueMap?.productMap) {
      setLoading(true);

      openProposal({
        userEmail: email,
        organization: state.organization?.name,
      });

      setTimeout(() => setLoading(false), 3000);
    } else {
      handleCreateProposal(email, state, setState);
    }

    setIsSelectOpened(false);
  };

  return (
    isPortalOneascentAdvisor() &&
    state.artBoardModal && (
      <Container align={"center"} justify={"space-between"} vertical>
        <StyledModal className={"art-board-modal"} open={true}>
          <div style={{ marginBottom: 11 }}>
            <OneAscentLogo style={{ width: 162 }} />
          </div>
          <Flex
            align={"end"}
            justify={"space-between"}
            style={{
              paddingBottom: 10,
              borderBottom: `1px solid ${token.colorGrey3}`,
            }}
          >
            <Flex vertical style={{ color: "#253A58" }}>
              <span style={{ fontSize: 13 }}>Welcome</span>
              <span
                style={{
                  lineHeight: 1.2,
                  fontSize: 20,
                }}
              >
                <b>
                  {state.personalInfo?.firstName} {state.personalInfo?.lastName}
                </b>
              </span>
            </Flex>
            <Button
              onClick={() => state.closeModal("artBoardModal")}
              size={"small"}
              shape={"round"}
              style={{
                width: 138,
                color: "#4897D1",
                borderColor: "#4897D1",
                background: "#fff",
              }}
            >
              Advisor Portal
            </Button>
          </Flex>
          <p>
            Launch a client dashboard by searching below, or click to navigate.
          </p>
          <Flex justify={"space-between"} wrap={"wrap"}>
            <Flex vertical style={{ maxWidth: 238 }}>
              <StyledSelect
                allowClear
                disabled={state.loading || loading}
                filterOption={(input, option) => {
                  setIsSelectOpened(input?.length > 2);
                  return (option?.label?.toLowerCase() ?? "").includes(input);
                }}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onClear={() => setIsSelectOpened(false)}
                onSelect={handleManagedUserSelect}
                open={isSelectOpened}
                optionFilterProp={"children"}
                options={managedUsers}
                placeholder={"Search existing..."}
                showSearch
                suffixIcon={<SearchOutlined style={{ fontSize: 16 }} />}
              />
              <p style={{ color: token.colorGrey1, marginTop: 10 }}>
                Type 3 or more characters to search for an existing client or
                prospect.
              </p>
            </Flex>
            <Flex gap={16} vertical>
              <StyledButton
                block
                onClick={handleAddProspect}
                shape={"round"}
                size={"small"}
              >
                + New Prospect
              </StyledButton>
              <StyledButton
                block
                onClick={handleAddClient}
                shape={"round"}
                size={"small"}
              >
                + New Client
              </StyledButton>
              <ButtonDemoMode
                background={"#e5f2ff"}
                borderColor={"#4897d1"}
                color={"#4897d1"}
                organization={state.organization?.name}
                userManagerEmail={state._id}
                width={"100%"}
              />
            </Flex>
          </Flex>
        </StyledModal>
        <img
          src={BottomChart}
          alt="Bottom chart"
          style={{ position: "fixed", bottom: 0, width: "100vw", opacity: 0.1 }}
        />
        <img
          src={BottomLine}
          alt="Bottom line"
          style={{ position: "fixed", bottom: 0, width: "100vw" }}
        />
      </Container>
    )
  );
};

export default ModalAdvisorArtBoard;
